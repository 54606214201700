import React from "react";
import "../../../../styles/components/home/button/button.css";
import arrowBlack from "../../../../Assets/ic-arrow-right.svg";
import arrowWhite from "../../../../Assets/arrow_btn.svg";
import { Link } from "react-router-dom";

type PropType = {
	isBlue: boolean;
	title: string;
	link: string;
};

export default function Button({ isBlue, title, link }: PropType) {
	return (
		<div className="button__container">
			<Link to={link === "/" ? "/" :"/signup"} style={{ textDecoration: "none" }}>
				<button
					className={isBlue ? "button__btn blue_btn" : "button__btn"}
					// style={isBlue ? { backgroundColor: "#3d94f9", color: "#fff" } : {}}
				>
					{title} <img src={isBlue ? arrowWhite : arrowBlack} alt="button arrow" />
				</button>
			</Link>
		</div>
	);
}

import React, { useEffect, useState } from "react";
import "../styles/pages/register/register.css";
import logo from "../Assets/incento_cropped.png";
import arrow_next from "../Assets/arrow-right-line (3).svg";
import arrow_gray from "../Assets/arrow_gray.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import script from "../scripts/register.json";
import { Link } from "react-router-dom";
import validEmailIcon from "../Assets/101.svg";
import invalidEmailIcon from "../Assets/105.svg";
import axios from "axios";
import { Navigate } from "react-router-dom";

import PageLayout from "../components/PageLayout"

export default function RegisterConnect() {
  const [login, setLogin] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");
  const [wrong, setWrong] = useState(false); // email not valid
  const [valid, setValid] = useState(false); // email valid
  const [notAll, setNotAll] = useState(false); // not all inputs filled
  const [passwordStrong, setPasswordStrong] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState<boolean | null>(null); // passwords did not match

  useEffect(() => {
    if (password && password2) {
      if (password !== password2) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
    } else {
      setPasswordMatch(null);
    }
    if (
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&+-]{10,}$/.test(
        password
      )
    ) {
      setPasswordStrong(true);
    } else {
      setPasswordStrong(false);
    }
  }, [password, password2]);

  function checkEmailValidity(e: React.MouseEvent<HTMLButtonElement>) {
    if (valid && passwordMatch && password && password2 && !notAll && !wrong) {
    }
  }

  function checkUsernameValidity(e: React.MouseEvent<HTMLButtonElement>) {
    if (username === "") {
      setUsernameValid(false);
    } else {
      axios
        .get(process.env.REACT_APP_SERVER_BASE_PATH + "account/check-username", {
          params: {
            username: username,
          },
        })
        .then((response) => {
          setUsernameValid(response.data.validFlag);
          if (!response.data.validFlag) {
            alert("이미 사용중인 아이디입니다.");
          }
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
          alert(e);
          // window.location.href = "/connect"
        });
    }
  }

  return (
    <PageLayout>
      <div className="h-screen w-screen bg-gradient-to-r from-blue-500 to-blue-200 overflow-x-hidden">
        {/* <div className="w-screen mx-auto pb-5">
        <NavBar
          navbarbgColour="bg-white"
          navbarColour={true}
          blackText="text-[#30313c]"
          whiteText="text-white"
        />
      </div> */}
        <div className="flex justify-center items-center w-full h-full">
          <div className="flex flex-col justify-center items-center w-full ">
            {login && (
              <Navigate
                to="/connect/agree"
                state={{
                  username: username,
                  password: password,
                  email: name,
                  number: number,
                }}
                replace={true}
              />
            )}
            <div className="visible lg:invisible font-bold mt-6 text-gray-800">
              로그인은 PC환경에서 이용해주세요
            </div>
            <div className="invisible lg:visible flex flex-col ">
              <div className="flex flex-row mb-3">
                <p className="text-sm font-bold">{script.path[0]}</p>
                <img className="h-5" src={arrow_gray} alt="" />
                <p className="text-sm">{script.path[1]}</p>
              </div>

              <div className="font-bold text-lg text-white">{script.title}</div>

              <div className="flex items-center justify-center">
                <div className="w-full max-w-[23rem] mt-5 flex flex-col gap-5">
                  <div className="email__field field">
                    <div>
                      <p style={notAll ? {} : { display: "none" }}>
                        {script.wrong[0]}
                      </p>
                    </div>
                    <div className="flex flex-row content-center justify-end">
                      <button
                        onClick={checkUsernameValidity}
                        className={`text-white  ${usernameValid
                          ? "email_check"
                          : "email_check username_not_checked"
                          }`}
                      >
                        중복 확인
                      </button>
                    </div>

                    <div className="relative">
                      <input
                        className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
                        type="username"
                        value={username}
                        onChange={(e) => {
                          setUsernameValid(false);
                          setUsername(e.target.value);
                        }}
                        name="username"
                        id="username"
                        placeholder={script.username[1]}
                      />
                      {usernameValid && (
                        <img
                          src={validEmailIcon}
                          className="absolute -mt-8 right-2"
                          alt="valid email icon"
                        />
                      )}
                    </div>
                    <p
                      className="valid_msg"
                      style={valid ? {} : { display: "none" }}
                    >
                      {script.valid}
                    </p>
                    {wrong && (
                      <img
                        src={invalidEmailIcon}
                        className="icon_email"
                        alt="invalid email icon"
                      ></img>
                    )}
                    <p
                      className="error_msg"
                      style={wrong ? {} : { display: "none" }}
                    >
                      {script.wrong[1]}
                    </p>
                  </div>

                  <div className="">
                    <input
                      className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      name="name"
                      id="name"
                      placeholder={script.email[1]}
                    />
                  </div>

                  <div className="">
                    <input
                      className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
                      type="text"
                      value={number}
                      onChange={(e) => {
                        setNumber(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
                      }}
                      name="number"
                      id="number"
                      placeholder={script.number[1]}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <div>
                      <div className="relative">
                        <input
                          className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
                          type={type1}
                          name="password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          id="password"
                          placeholder={script.password[1]}
                        />
                        <div className="absolute -mt-8 right-2">
                          <Eye
                            password={password}
                            type={type1}
                            setType={setType1}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="relative">
                      <input
                        className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
                        type={type2}
                        name="password2"
                        value={password2}
                        onChange={(e) => {
                          setPassword2(e.target.value);
                        }}
                        id="password2"
                        placeholder={script.password[2]}
                      />
                      <div className="absolute -mt-8 right-2">
                        <Eye
                          password={password2}
                          type={type2}
                          setType={setType2}
                        />
                      </div>

                      <div
                        className={
                          password.length > 0 && passwordStrong === false
                            ? "flex flex-row items-center justify-center bg-white rounded-lg p-1 mt-1.5 "
                            : "invisible"
                        }
                      >
                        <span className="text-[#ff7474] text-xs">
                          비밀번호에 소문자, 숫자, 특수문자 포함 10자 이상
                        </span>
                      </div>
                      {passwordMatch != null ? (
                        passwordMatch === true ? (
                          <div
                            className="bg-white rounded-lg p-1 mt-1.5 flex flex-row items-center justify-center"
                          >
                            <span className="text-green-600 text-xs">
                              {script.match}
                            </span>
                          </div>
                          // <span className="bg-white rounded-lg p-1 mt-1.5 text-green-600 text-xs w-full">
                          //   {script.match}
                          // </span>
                        ) : (
                          <div
                            className="bg-white rounded-lg p-1 mt-1.5 flex flex-row items-center justify-center"
                          >
                            <span className=" text-[#ff7474] text-xs">
                              {script.notmatch}
                            </span>
                          </div>
                        )
                      ) : (
                        <span className="text-[#ff7474] text-xs invisible">
                          {"."}
                        </span>
                      )}
                    </div>
                    {/* <div className={passwordMatch === true ? "" : "invisible"}>
                  {script.match}
                </div>
                <div className={passwordMatch === false ? "" : "invisible"}>
                  {script.notmatch}
                </div> */}
                  </div>
                  <button
                    onClick={moveOn}
                    className="font-bold w-full px-6 py-2.5  text-md transition  duration-150
				   ease-in-out text-white bg-blue-500 rounded-md shadow hover:bg-gray-100 hover:text-gray-800 flex flex-row content-center justify-center -mt-4"
                  >
                    <p className="text-lg font-bold text-center">다음 단계</p>
                    {/* <img src={arrow_next} alt="" /> */}
                  </button>
                </div>
                {/* <Link to="/register/agree"> */}
                {/* <div className="sign" onClick={submitForm}> */}
                {/* <div  onClick={moveOn}>
                <img  className="bg-blue-500 rounded-[50%] w-[45px] h-[45px] p-1 m-5" src={arrow_next} alt="" />
              </div> */}
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>

      </div>
    </PageLayout>
  );

  function moveOn(e: any) {
    if (!username || !password || !name || !number) {
      alert("모든 항목들을 입력해주세요");
    } else if (!passwordMatch) {
      alert("비밀번호가 일치하지 않습니다. 다시 확인해주세요");
    } else if (!passwordStrong) {
      alert(
        "비밀번호는 소문자, 숫자, 특수문자 포함 10자 이상으로 설정해주세요"
      );
    } else if (!usernameValid) {
      alert("아이디 중복확인이 필요합니다.");
    } else {
      setLogin(true);
    }
  }
}

function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

type EyeProps = {
  password: string;
  type: string;
  setType: Function;
};

function Eye({ password, type, setType }: EyeProps) {
  return (
    <React.Fragment>
      {type === "password" ? (
        <AiOutlineEyeInvisible
          className="eye__icon"
          style={{ color: "#30313c", fontSize: "20px" }}
          onClick={(e) => {
            setType("text");
          }}
        />
      ) : (
        <AiOutlineEye
          className="eye__icon"
          style={{ color: "#30313c", fontSize: "20px" }}
          onClick={(e) => {
            setType("password");
          }}
        />
      )}
    </React.Fragment>
  );
}

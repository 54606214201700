import React, { useRef, useState } from "react";
import imgLogo from "../img/로고.png";
import "../styles/pages/forgot/forgot.css";
import script from "../scripts/login.json";
import arrowWhite from "../Assets/arrow_blue.svg";
import arrowBlack from "../Assets/ic-arrow-right.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PageLayout from "../components/PageLayout";
export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [after, setAfter] = useState(false);
  const [email, setEmail] = useState("");

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      submitID(e);
    }
  }

  function hideEmail(email: string) {
    let parts = email.split("@");

    console.log(parts);
    if (parts[0].length > 2) {
      return (
        parts[0].slice(0, 2) + "*".repeat(parts[0].length - 2) + "@" + parts[1]
      );
    } else {
      return (
        parts[0].slice(0, 1) +
        parts[0].slice(1, parts[0].length).replaceAll("", "*") +
        "@" +
        parts[1]
      );
    }
  }

  return (
    <PageLayout>
    <div className="h-screen w-screen bg-gradient-to-r from-blue-500 to-blue-200  overflow-x-hidden">
      {/* <div className="w-screen mx-auto pb-5">
        <NavBar
          navbarbgColour="bg-white"
          navbarColour={true}
          blackText="text-[#30313c]"
          whiteText="text-white"
        />
      </div> */}
      <div className=" flex justify-center items-center w-full h-full  ">
        <div className="flex flex-col justify-center items-center w-full ">
          <div className="w-full flex justify-center items-center">
            <Link to={"/"}>
              <img src={imgLogo} alt="Logo" />
            </Link>
          </div>

          {after ? (
            <div className="w-full max-w-[23rem] mt-10">
              <label htmlFor="text">{`요청이 접수되었습니다. 해당 아이디랑 연결된 ${hideEmail(
                email
              )} 비밀번호 재설정 이메일을 보내드렸습니다`}</label>
            </div>
          ) : (
            <div className="w-full max-w-[23rem] mt-10">
              <label htmlFor="text">가입한 아이디를 입력해주세요</label>
              <div className=" mb-6 mt-5">
                <input
                  onKeyDown={handleKeyDown}
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  id="username"
                  className="block  border border-solid border-gray-300 bg-white  w-full  px-3 py-2.5  text-base font-normal text-gray-800 rounded-lg focus:outline-none transition ease-in-out"
                  placeholder={"아이디를 입력해주세요"}
                />
              </div>

              <button
                className=" font-bold w-full px-6 py-2.5  text-md transition  duration-150
				  ease-in-out text-white bg-blue-500 rounded-md shadow hover:bg-gray-100 hover:text-gray-800"
                onClick={submitID}
              >
                비밀번호 재설정하기
              </button>
            </div>
          )}
        </div>
      </div>

     
    </div>
    </PageLayout>
  );

  function submitID(e: any) {
    let backend =
      process.env.REACT_APP_SERVER_BASE_PATH +
      "account/get-email-from-username/";
    axios
      .get(backend, { params: { username: username } })
      .then((response) => {
        const email = response.data.email;

        let backend =
          process.env.REACT_APP_SERVER_BASE_PATH + "account/password_reset/";
        axios
          .post(backend, { email: email })
          .then((response) => {
            // console.log(response)
            setEmail(email);
            setAfter(true);
          })
          .catch((err) => {
            setAfter(true);
            // console.log(err);
            // if (err.response.status === 400) {
            //     alert("아이디랑 비밀번호를 확인해주세요")
            // }
            // if (err.response.status === 500) {

            // }
          });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          alert("아이디랑 비밀번호를 확인해주세요");
        }
      });
  }
}

import React, { useEffect, useState, useRef } from "react";
import background from "../../../Assets/background_2.png";
import "../../../styles/components/home/main/main.css";
import Button from "./components/Button";
import mainImage from "../../../Assets/main_home.svg";

type Proptype = {
  title: string | string[];
  subtitle: string;
  button: string;
  isHome: boolean;
  note: string;
  link: string;
};

export default function Main({
  title,
  subtitle,
  isHome,
  note,
  button,
  link,
}: Proptype) {
  const height = useWindowSize();

  const style = {
    "--height": height,
    backgroundImage: `url(${background})`,
  } as React.CSSProperties;

  return (
    <section
      className={
        !isHome ? "home__main__container home_success" : "home__main__container"
      }
      id="main"
      style={style}
    >
      {isHome ? (
        <h1 className="main_title">{title}</h1>
      ) : (
        <div className="main_title_success">
          {Array.isArray(title) &&
            title.map((line, index) => {
              return <p key={index}>{line}</p>;
            })}
        </div>
      )}

      {isHome && (
        <div
          className="main_subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <Button isBlue={false} title={button} link={link} />
      {isHome && <p className="main__note">{note}</p>}
      <img src={mainImage} alt="메인페이지" className="main__image" />
    </section>
  );
}

function useWindowSize() {
  const [height, setHeight] = useState(0);
  React.useLayoutEffect(() => {
    function updateHeight() {
      const footer: HTMLDivElement = document.getElementById(
        "footer"
      ) as HTMLDivElement;
      setHeight(footer?.clientHeight);
    }
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  return height;
}

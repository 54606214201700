import React, { useRef, useState } from "react";
import logo from "../Assets/incento_cropped.png";
import "../styles/pages/login/login.css";
import script from "../scripts/login.json";
import arrowWhite from "../Assets/arrow_blue.svg";
import arrowBlack from "../Assets/ic-arrow-right.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";

export default function LoginDev() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [type, setType] = useState("password");
	const [wrong, setWrong] = useState(false);
	const navigate = useNavigate();

	function handleKeyDown(e: any) {
		if (e.key === 'Enter') {
			submitForm(e)
		}
	}

	return (
		<div className="login__container">
			<div className="main__container">
				<Link to={"/"} style={{ textDecoration: "none" }}>
					<div className="logo__container">
						<img src={logo} alt="" />
						<p className="brand">incento</p>
					</div>
				</Link>

				<div className="inputs">
					<div className="email__field field">
						<label htmlFor="username">{script.fields[0][0]}</label>
						<input
							onKeyDown={handleKeyDown}
							type="username"
							value={username}
							onChange={(e) => {
								setUsername(e.target.value);
							}}
							name="username"
							id="username"
							placeholder={script.fields[0][1]}
						/>
					</div>
					<div className="password__field field">
						<label htmlFor="password">{script.fields[1][0]}</label>
						<input
							onKeyDown={handleKeyDown}
							type={type}
							name="password"
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							id="password"
							placeholder={script.fields[1][1]}
						/>

						{type === "password" ? (
							password.length > 0 ? (
								<AiOutlineEyeInvisible
									className="eye__icon"
									style={{ color: "#9ec2fd", fontSize: "20px" }}
									onClick={(e) => {
										setType("text");
									}}
								/>
							) : (
								<></>
							)
						) : password.length > 0 ? (
							<AiOutlineEye
								className="eye__icon"
								style={{ color: "#9ec2fd", fontSize: "20px" }}
								onClick={(e) => {
									setType("password");
								}}
							/>
						) : (
							<></>
						)}
					</div>
					{wrong && <p className="wrong__text">{script.wrong}</p>}
					<a className="find__data" href="/login">
						아이디/비밀번호 찾기 <img src={arrowWhite} alt="" />
					</a>
				</div>

				<button className="login__btn" onClick={submitForm}>로그인</button>
				<div className="login__footer">
					<p className="left">{script.footer.left}</p>
					<Link to="/register" style={{ textDecoration: "none" }}>
						<p className="right">
							{script.footer.right} <img src={arrowBlack} alt="" />
						</p>
					</Link>
				</div>
			</div>
		</div>
	);

	function submitForm(e: any) {
		if (!username || !password) {
			alert("비밀번호랑 아이디를 입력해주세요");
		} else {
			let backend = 'https://api-dev.incento.kr/api/v1/' + "account/login-merchant/"
			axios
				.post(backend, { username, password })
				.then((response) => {
					console.log(response);
					document.cookie = "access=" + response.data.access + ";domain=.incento.kr";
					document.cookie = "refresh=" + response.data.refresh + ";domain=.incento.kr";

					navigate("/select-dev");
					// document.cookie = "shop=" + response.data.user.shop + ";domain=.incento.kr";
					// window.location.href = 'https://dashboard.incento.kr'
					// navigate("/signup/success");
				})
				.catch((err) => {
					if (err.response.status === 400) {
						alert("아이디랑 비밀번호를 확인해주세요")
					}
				});
		}
	}
}

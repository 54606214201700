import { Box, Paper, Typography } from "@mui/material";

export interface WhyIncentoPaperProps {
  iconUrl: string;
  title: string;
  body: string[];
}

export default function WhyIncentoPaper({
  body,
  iconUrl,
  title,
}: WhyIncentoPaperProps) {
  return (
    <Paper className="flex flex-col justify-center items-center gap-4 w-[220px] md:w-[270px] p-6">
      <img src={iconUrl} alt={title} width={60} />
      <p className="flex justify-center font-bold md:text-[18px] text-[14px]">
        {title}
      </p>
      <Box>
        {body.map((text) => (
          <p className="flex justify-center text-gray-300 md:text-[16px] text-[12px]">
            {text}
          </p>
        ))}
      </Box>
    </Paper>
  );
}

import { useState } from "react";

import PersonIcon from "../Assets/png/PersonIcon.png";
import HeartIcon2 from "../Assets/png/HeartIcon2.png";
import ShoppingBagIcon from "../Assets/png/ShoppingBagIcon.png";
import NotepadIcon from "../Assets/png/NotepadIcon.png";
import CoinIcon from "../Assets/png/CoinIcon.png";

const StatisticItem = ({ icon, label, value, textColor }) => {
  return (
    <div className="flex flex-row justify-between p-[3%] ">
      <div className="flex flex-row gap-2 items-center">
        <img src={icon} alt={label} className="h-5" />
        <p className={`${textColor} text-sm lg:text-base font-semibold`}>
          {label}
        </p>
      </div>
      <p className="font-bold text-base lg:text-2xl">{value}</p>
    </div>
  );
};

const statisticsDataW = [
  {
    icon: HeartIcon2,
    label: "리퍼럴 횟 수",
    value: "5,466 번",
    textColor: "text-red-400",
  },
  {
    icon: NotepadIcon,
    label: "신규 고객 수",
    value: "1,330 명",
    textColor: "text-slate-400",
  },
  {
    icon: ShoppingBagIcon,
    label: "신규 구매자 수",
    value: "689 명",
    textColor: "text-pink-400",
  },
  {
    icon: CoinIcon,
    label: "신규 매출",
    value: "93,720,990 원",
    textColor: "text-yellow-400",
  },
];

const statisticsDataF = [
  {
    icon: HeartIcon2,
    label: "리퍼럴 횟 수",
    value: "10,765 번",
    textColor: "text-red-400",
  },
  {
    icon: NotepadIcon,
    label: "신규 고객 수",
    value: "1,997 명",
    textColor: "text-slate-400",
  },
  {
    icon: ShoppingBagIcon,
    label: "신규 구매자 수",
    value: "459 명",
    textColor: "text-pink-400",
  },
  {
    icon: CoinIcon,
    label: "신규 매출",
    value: "70,289,750 원",
    textColor: "text-yellow-400",
  },
];

export default function CustomerTestimonies() {
  const [showFirst, setShowFirst] = useState(true);

  const toggleButton = () => {
    setShowFirst((prev) => !prev);
  };

  return (
    <div className="flex-col bg-[#f6f9ff] lg:flex-row gap-10 lg:gap-0 flex px-[10%] py-[5%] min-h-max">
      <div className="w-full lg:w-1/2">
        <div class="flex flex-col  md:flex-row">
          <div class="flex flex-col items-start gap-6 p-2">
            <p class="font-medium text-gray-500">고객 사례</p>
            <div class="flex flex-col items-start font-bold text-[18px] lg:gap-2 lg:text-3xl lg:font-semibold">
              <p className="">리퍼럴이 일어나면 신규 고객, 신규 구매자,</p>
              <p class="">
                <span class="text-blue-500">신규 매출 </span> 나옵니다.
              </p>
            </div>

            <div class="flex flex-col items-start text-[14px] md:text-base text-gray-500">
              <p class="">인센토 솔루션을 통해 리퍼럴 받은 사람의 20%는</p>
              <p class="">신규 고객으로 가입하고 이중 23%는</p>
              <p class="">신규 구매자로 전환됩니다. 이 모든 과정은 인센토</p>
              <p class="">솔루션을 통해 정확하게 트래킹 됩니다.</p>
            </div>

            <div className=" flex flex-col items-start">
              {showFirst ? (
                <button onClick={toggleButton} className="">
                  <div class="mt-4 flex flex-col items-start gap-10 h-[90px]">
                    <div class="flex flex-col items-start">
                      <div className="flex items-start gap-2">
                        <img src={PersonIcon} alt="" className="h-5 lg:h-8" />
                        <p class="pb-1 text-[18px] lg:text-2xl font-semibold">
                          W사
                        </p>
                      </div>

                      <p class="text-[14px] font-light text-gray-300">
                        - 44일간
                      </p>
                    </div>

                    <div class="text-gray flex flex-col items-start gap-10">
                      <div className="flex items-start gap-2">
                        <img
                          src={PersonIcon}
                          alt=""
                          className="h-5 lg:h-8 opacity-30 "
                        />
                        <p class="pb-2 text-[18px] lg:text-2xl font-semibold text-gray-200">
                          F사
                        </p>
                      </div>
                    </div>
                  </div>
                </button>
              ) : (
                <button onClick={toggleButton}>
                  <div class="mt-4 flex flex-col items-start gap-10 h-[90px]">
                    <div class="flex flex-col items-start">
                      <div className="flex items-start gap-2">
                        <img
                          src={PersonIcon}
                          alt=""
                          className="h-5 lg:h-8 opacity-30"
                        />
                        <p class="pb-1 text-[18px] lg:text-2xl font-semibold text-gray-200">
                          W사
                        </p>
                        <p class="text-sm font-light text-gray-300"></p>
                      </div>
                    </div>

                    <div class="flex flex-col items-start">
                      <div className="flex items-start gap-2">
                        <img src={PersonIcon} alt="" className="h-5 lg:h-8" />
                        <p class="pb-1 text-[18px] lg:text-2xl font-semibold">
                          F사
                        </p>
                      </div>

                      <p class="text-[14px] font-light text-gray-300">
                        - 96일간
                      </p>
                    </div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={toggleButton}
        className="w-full lg:w-1/2 cursor-pointer flex border border-gray-100 shadow-md flex-col py-[5%] px-[5%] bg-white rounded-xl hover:bg-gray-100"
      >
        {showFirst
          ? statisticsDataW.map((statistic, index) => (
              <StatisticItem
                key={index}
                icon={statistic.icon}
                label={statistic.label}
                value={statistic.value}
                textColor={statistic.textColor}
              />
            ))
          : statisticsDataF.map((statistic, index) => (
              <StatisticItem
                key={index}
                icon={statistic.icon}
                label={statistic.label}
                value={statistic.value}
                textColor={statistic.textColor}
              />
            ))}
      </div>
    </div>
  );
}

import { ReactNode } from "react";
import Footer from "./Footer";
import Navigation from "./NavBar";

export default function PageLayout({ children }: { children: ReactNode }) {
  return (
    <>
      <Navigation />
      <div className="pt-[64px]  md:pt-[80px] lg:pt-[96px]">{children}</div>
      <div className="w-screen mx-auto py-4 flex justify-center bg-[#1a1a1a]">
        <Footer />
      </div>
    </>
  );
}

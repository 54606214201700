import React from "react";
// import footerTopImage from "../img/components/body/footerTopImage.png";
import { Link } from "react-router-dom";

export default function Subscribe() {
  return (
    <div class="flex flex-col md:flex-row bg-[#f6f6f6] justify-between items-center px-2 md:px-[10%] font-semibold py-8 md:py-[5%]">
      <div class="w-full md:w-1/2 text-center text-[18px] md:text-left md:text-3xl xl:text-4xl">
        <p class="">
          <span class="text-blue-500 ">자사몰 성장</span>시키기 위해
        </p>
        <p class="pt-0 md:pt-2">
          <span class="text-blue-500">구독</span> 하세요
          <span class="font-bold">!</span>
        </p>
        <p class="pt-3 text-[14px] xl:text-xl font-thin">
          다양한 자사몰에 맞는 합리적인 가격을 경험 하세요.
        </p>
      </div>

      <div class="w-[80%] md:w-1/2 bg-white md:py-[3%] py-4 mt-2 md:mt-0 gap-3 rounded-lg border flex flex-col items-center border-gray-300">
        <h1 class="text-center text-[14px] md:text-2xl xl:text-3xl">구독</h1>
        <Link to="/inquiries">
          <button class="bg-black px-2 md:px-10 py-3 rounded-3xl">
            <p class="text-white text-[14px] md:text-xl text-center">
              문의하기
            </p>
          </button>
        </Link>
      </div>
    </div>
  );
}

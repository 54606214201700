import React, { useEffect, useState } from "react";
import "../styles/pages/select/select.css";

import Main from "../components/home/main/Main";
import DropDownSection from "../components/home/DropdownSection/DropDownSection";
import ImageSection from "../components/home/ImageSection/Section";
import FeaturesSection from "../components/home/FeaturesSection/FeaturesSection";
import MarketingSection from "../components/home/MarketingSection/MarketingSection";
import CustomSection from "../components/home/MarketingSection/CustomSection";

import Prompt from "../components/home/Prompt";
import Footer from "../components/home/Footer";
import mainScript from "../scripts/home/main.json";
import { useNavigate } from "react-router-dom";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from 'crypto-js/enc-base64';
import LoginNavbar from "../components/home/LoginNavbar";
import DefaultImage from "../Assets/default-icon.png";
import Breadcrumbs from "../Assets/breadcrumbs.svg";
import axios from "axios";


interface merchantShop {
    name: string,
    sid: string,
}

export default function ShopSelectDev() {

    const [shops, setShops] = useState([]);

    useEffect(() => {
        var access;
        var refresh;
        var key;

        var cookie_entries = document.cookie.split("; ");
        for (var j = 0; j < cookie_entries.length; j++) {
            if (cookie_entries[j].includes("access=")) {
                [key, access] = cookie_entries[j].split("=");
            }
            if (cookie_entries[j].includes("refresh=")) {
                [key, refresh] = cookie_entries[j].split("=");
            }
        }
        if (!access) {
            window.location.href = 'https://www.incento.kr/login-dev'
        }


        let config = {
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + access,
                "Content-Type": "application/json",
            },
        };


        axios
            .get('https://api-dev.incento.kr/api/v1/' + "merchant-shop/list/", config)
            .then((response) => {
                setShops(response.data)
            })

    }, [])

    function selectHandler(sid: string) {
        document.cookie = "shop=" + sid + ";domain=.incento.kr";
        window.location.href = "https://dashboard-dev.incento.kr";
    }

    return (
        <div className="select__container" id="home">
            <LoginNavbar />
            <div className="select__box">
                <div className="select__title">
                    <p className="select__title_main">내 채널 목록</p>
                    <p className="select__title_description">대시보드로 이동할 샵 계정을 선택해주세요</p>
                </div>
                {
                    shops.map((shop: merchantShop, i) => {
                        return (
                            <div className="select__shops" key={shop.sid} onClick={() => { selectHandler(shop.sid) }}>
                                <div className="select__shop">
                                    <div className="select__shop_header">
                                        <img src={DefaultImage} className="select__shop_image" alt="아이콘" />
                                        <p className="select__shop_image_header">{shop.name}</p>
                                    </div>
                                    <img src={Breadcrumbs} alt="arrow-right" className="select__shop_select" />
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    );
}
import React from "react";
import "../../styles/components/details/detailsOne/detailsOne.css";

type props = {
  setStep: Function;
  name: string;
  setName: Function;
  shopUrl: string;
  setShopUrl: Function;
};

export default function DetailsOne({ setStep, name, setName, shopUrl, setShopUrl }: props) {
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleShopUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShopUrl(event.target.value);
  };

  return (
    <div className="details_content_one">
      <div className="details_step text-gray-800">
        <div className="details_step_first "></div>
        <div className="details_step_divider "></div>
        <div className="details_step_second ">2</div>
        <div className="details_step_divider"></div>
        <span className="details_step_third">3</span>
      </div>
      <p className="details_title">자사몰 정보를 입력하세요</p>
      <div className="flex items-center justify-center">
        <div className="w-full max-w-[23rem] mt-5 flex flex-col gap-5 ">
          <input
            className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
            placeholder="자사몰 명"
            value={name}
            onChange={handleNameChange}
          ></input>
          <input
            className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
            placeholder="자사몰 주소 (카페24)"
            value={shopUrl}
            onChange={handleShopUrlChange}
          ></input>
          <button
            className=" font-bold w-full px-6 py-2.5  text-md transition  duration-150
				   ease-in-out text-white bg-blue-500 rounded-md shadow hover:bg-gray-100 hover:text-gray-800"
            onClick={() => {
              setStep(2);
            }}
          >
            다음 단계
          </button>
        </div>
      </div>
    </div>
  );
}

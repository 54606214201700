import { useEffect } from "react";
import 리퍼럴1 from "../img/PageService/리퍼럴1.png";
import 리퍼럴2 from "../img/PageService/리퍼럴2.png";
import 리퍼럴3 from "../img/PageService/리퍼럴3.png";
import 위젯바4 from "../img/PageService/위젯바4.png";
import 리퍼럴위젯 from "../img/PageService/리퍼럴 위젯.png";
import 마스크그룹17 from "../img/PageService/마스크 그룹 17.png";
import 그룹2544 from "../img/PageService/그룹 2544.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, EffectFade, Navigation } from "swiper";
import { Link } from "react-router-dom";
import PageLayout from "../components/PageLayout";

export default function PageServiceIntro() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <PageLayout>
        <div className="w-full h-auto min-h-[860px] md:min-h-[1080px] flex flex-col justify-center items-center relative bg-[#f3fafd] ">
          <div className="w-full max-w-[1920px] pt-32 px-[15%]">
            <div className="flex flex-col justify-center  md:items-start gap-y-5">
              <div className="text-sky-600 font-bold pb-2">추천 마케팅</div>
              <div className="font-bold  text-xl md:text-3xl text-gray-800">
                5분 만에 가장 효과적인 추천 마케팅으로
              </div>
              <div className="font-bold text-xl md:text-3xl text-gray-800">
                신규 고객을 획득하세요
              </div>
            </div>

            <div className="w-full rounded-lg drop-shadow-md mt-16">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                effect={"fade"}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                //   pagination={{
                //     clickable: true,
                //   }}
                //   navigation={true}
                modules={[Autoplay, EffectFade, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={리퍼럴1} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={리퍼럴2} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={리퍼럴3} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={위젯바4} alt="" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="w-full h-screen  max-h-[1080px] flex flex-col justify-center items-center relative  ">
          <div className="w-full max-w-[1920px] md:px-[15%]">
            <div className="flex flex-col justify-center items-center md:items-start gap-4 ">
              <div className="text-sky-600 font-bold pb-2">추천 위젯</div>
              <div className="font-bold text-xl md:text-3xl text-gray-800">
                내 사이트에 어울리게 커스텀하고
              </div>
              <div className="font-bold text-xl md:text-3xl text-gray-800">
                추천 마케팅을 시작해 보세요
              </div>
            </div>
            <div className="flex flex-row justify-end items-center">
              <img src={리퍼럴위젯} alt="" width={650} />
            </div>
          </div>
        </div>

        <section className="h-screen max-h-[1080px] flex justify-center items-center relative pt-20 w-full">
          <div className="w-full max-w-[1920px] text-white text-xs md:text-xl font-bold absolute px-10 md:px-[10%] gap-y-3">
            <div className="md:pl-20 mb-96">
              <div className=" font-bold text-sm pb-2 ">
                정확한 데이터로 정확한 CRM
              </div>
              <div className="font-bold text-xl md:text-2xl  ">
                정확하고 입체적으로 분석된 고객 정보로
              </div>
              <div className="font-bold text-xl md:text-2xl  ">
                세일즈를 시작할 수 있어요
              </div>
            </div>
          </div>
          <img src={마스크그룹17} alt="" className="w-full h-full" />
        </section>

        <div className="flex flex-col justify-center items-center relative ">
          <div className=" max-w-[1920px] py-20 md:px-56  w-full h-auto ">
            <div className="flex flex-col md:flex-row justify-center items-center ">
              <img
                className="w-[250px] h-[200px] md:max-w-[250px] md:max-h-[300px]"
                src={그룹2544}
                alt=""
              />
              <div className="flex flex-col justify-center items-center pt-5 md:items-start md:pl-32">
                <div className="text-gray-800 font-bold text-xl md:text-2xl">
                  결제 없이{" "}
                </div>
                <div className="text-gray-800 font-bold text-xl md:text-2xl">
                  무료로 사용해 보세요!
                </div>
                <Link to="/connect/register">
                  <div className="bg-gray-800 text-white px-8 cursor-pointer py-2 rounded-[4px] flex justify-center items-center text-md md:text-lg mt-6">
                    무료로 시작하기
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

      </PageLayout>
    </>
  );
}
import React, { useEffect, useState } from "react";
import "../styles/pages/register/register.css";

import PageLayout from "../components/PageLayout";

import ask_demo_img from "../img/pages/AskDemo/ask_demo_img.png";
import script_ from "../scripts/ask_demo.json";
import axios from "axios";

import Swal from "sweetalert2";

export default function AskDemo() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [shopname, setShopname] = useState("");
  const [url, setUrl] = useState("");
  const [number, setNumber] = useState("");
  const [extra, setExtra] = useState("");

  function submitForm(e: any) {
    if (!shopname || !name || !number || !email) {
      alert("필수 항목은 입력해 주세요.");
    } else {
      axios.post(
        process.env.REACT_APP_SERVER_BASE_PATH + "landing/askdemo-submit/",
        {
          shopname,
          url,
          name,
          number,
          email,
          extra,
        }
      );
      (window as any).dataLayer.push({
        event: "Inquiry_completed",
      });
      resetFormAndShowConfirmation(e);
    }

    console.log(process.env.REACT_APP_SERVER_BASE_PATH);
  }

  function resetFormAndShowConfirmation(e: any) {
    setEmail("");
    setName("");
    setShopname("");
    setUrl("");
    setNumber("");
    setExtra("");
    Swal.fire({
      titleText: "감사합니다",
      text: "인센토 팀이 곧 연락드리겠습니다",
      icon: "success",
      confirmButtonText: "알겠어요!",
    });
  }

  return (
    <PageLayout>
      <div className="bg-white">
        <div className=" grid w-full items-start  grid-cols-5">
          <img
            src={ask_demo_img}
            alt="info img"
            className="mt-[80px] hidden lg:block  lg:col-span-3 max-h-full"
          />
          <div className="col-span-5 lg:col-span-2 mt-[80px] flex flex-col justify-center items-center">
            <p className="mt-[30px] mb-[10px] text-xl">{script_.title}</p>
            <div className="w-full max-w-[23rem]  ">
              <div className="flex flex-col mt-3 justify-center items-start w-full ">
                <div className="text-black text-sm p-1">
                  {script_.shopname[0]}{" "}
                  <span className="text-red-400 text-base">*</span>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={shopname}
                    onChange={(e) => {
                      setShopname(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder={script_.shopname[1]}
                    className="   px-3 w-full border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[5px] transition ease-in-out"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-3 justify-center items-start w-full ">
                <div className="text-black text-sm p-1">{script_.url[0]}</div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder={script_.url[1]}
                    className="   px-3 w-full border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[5px] transition ease-in-out"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-3 justify-center items-start w-full ">
                <div className="text-black text-sm p-1">
                  {script_.name[0]}{" "}
                  <span className="text-red-400 text-base">*</span>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder={script_.name[1]}
                    className="   px-3 w-full border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[5px] transition ease-in-out"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-3 justify-center items-start w-full ">
                <div className="text-black text-sm p-1">
                  {script_.number[0]}{" "}
                  <span className="text-red-400 text-base">*</span>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={number}
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder={script_.number[1]}
                    className="   px-3 w-full border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[5px] transition ease-in-out"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-3 justify-center items-start w-full ">
                <div className="text-black text-sm p-1">
                  {script_.email[0]}{" "}
                  <span className="text-red-400 text-base">*</span>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder={script_.email[1]}
                    className="   px-3 w-full border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[5px] transition ease-in-out"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-3 justify-center items-start w-full ">
                <div className="text-black text-sm p-1">{script_.extra[0]}</div>
                <div className="relative w-full">
                  <textarea
                    value={extra}
                    onChange={(e) => {
                      setExtra(e.target.value);
                    }}
                    name="name"
                    id="name"
                    rows={5}
                    placeholder={script_.extra[1]}
                    className="   px-3 w-full h-30 border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[5px] transition ease-in-out"
                  />
                </div>
              </div>
              <button
                onClick={submitForm}
                name="name"
                id="name"
                className="px-3 w-full h-[40px] py-2 mt-[30px] text-sm focus:outline-none font-normal bg-[#0f74ed] text-white rounded-[5px] transition ease-in-out"
              >
                신청하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

import React, { useEffect } from "react";
import "../../styles/components/home/navbar/navbar.css";
import logo from "../../Assets/incento_cropped.png";
import scripts from "../../scripts/home/navbar.json";
import { Link } from "react-router-dom";
import burger from "../../Assets/menu-4-line (1).svg";

export default function LoginNavbar() {
	const [show, setShow] = React.useState(false);
	const [navHide, setNavHide] = React.useState(true);

	React.useEffect(() => {
		// const div = document.getElementById("main");
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const handleScroll = () => {
		// const div = document.getElementById("main");
		// if (div) {
		let yScroll = window.scrollY;
		// console.log(yScroll);
		setShow(yScroll > 0);
		// }
	};

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			// used to collapse nav links on mobile when clicked outside navbar
			const elem = e.target as HTMLElement;
			// console.log(elem.classList);
			if (!elem?.classList.contains("nav_burger") && !elem?.classList.contains("mini__nav")) {
				setNavHide(true);
			}
		};

		window.addEventListener("click", handleClick);

		return () => {
			window.removeEventListener("click", handleClick);
		};
	}, []);

	return (
		<section
			className={
				show ? (!navHide ? "navbar__container show_nav hidenav" : "navbar__container show_nav") : "navbar__container"
			}
		>
			{/* <div className={navHide ? "mini__nav" : "mini__nav show"}>
				<a href="/">{scripts.links[0]}</a>
				<a href="/">{scripts.links[1]}</a>
			</div> */}
			<div className="nav__main">
				<img
					src={burger}
					onClick={() => {
						setNavHide(!navHide);
					}}
					alt="nav_burger"
					className="nav_burger"
				/>
				<div className="nav_left">
					<Link to={"/"} style={{ textDecoration: "none" }}>
						<div className="nav_logo">
							<img src={logo} alt="" />
							<strong className="nav_logo_name">{scripts.logo}</strong>
						</div>
					</Link>

				</div>
			</div>
		</section>
	);
}

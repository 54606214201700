import React, { useEffect } from "react";
import "../styles/pages/home.css";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";

export default function Pricing() {
  useEffect(() => {
    const redirectTo = "/";
    window.location.href = redirectTo;
  }, []);
  return (
    <div className="home__container">
      <Navbar />
      <Footer />
    </div>
  );
}

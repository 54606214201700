import { useState } from "react";
import { Link } from "react-router-dom";
import 로고 from "../img/로고.png";
import login_button from "../img/components/NavBar/login_button_large.png";
import inquiries from "../img/components/NavBar/inquiries.png";
import { Paper } from "@mui/material";

export default function NavBar() {
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <nav
        className={`hidden md:block  justify-center items-center fixed w-full z-50  h-16 transform ease-in-out duration-700 bg-transparent`}
      >
        <Paper
          elevation={0}
          className=" flex flex-row justify-between items-center w-full max-w-[1920px] px-10 max-h-[96px] "
        >
          <div className="flex flex-row justify-start gap-6 md:pl-[5%] pl-1">
            <Link to="/">
              <img
                src={로고}
                alt="Logo"
                className="-mt-3 md:w-[180px] w-[120px]"
              />
            </Link>
          </div>
          <div className=" flex flex-row justify-center items-center gap-2">
            <a
              href="https://incento-app.notion.site/ec2f6ca310ef44ed993e123411694f8f"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="p-2 hover:opacity-60  text-black duration-300">
                채용안내
                <span className="bg-green-200 px-2 py-1 rounded-md ml-2 text-green-500">
                  채용중
                </span>
              </p>
            </a>
            <Link to="/login">
              <img
                src={login_button}
                alt="login_button"
                className="h-20 lg:h-24 aspect-auto transform"
              />
            </Link>
            <Link to="/inquiries">
              <img
                src={inquiries}
                alt="inquiries"
                className="h-20  lg:h-24 aspect-auto transform"
              />
            </Link>
          </div>
        </Paper>
      </nav>
      <nav
        className={`w-full md:hidden fixed  z-50 flex justify-between items-center transform ease-in-out duration-700 bg-white`}
      >
        <Paper
          elevation={0}
          className=" justify-start px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8 w-full gap-x-10 "
        >
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <Link to="/" className="-mt-3 md:w-[152px] w-[120px]">
                <img src={로고} alt="Logo" className="w-full h-full" />
              </Link>
              <div className="md:hidden transform ease-in-out duration-700">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-[#30313c]"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-[#30313c]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 transform ease-in-out duration-700 md:mt-0 transform ease-in-out duration-700 ${
                navbar ? "block" : "hidden"
              }`}
            >
              {/* <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 transform ease-in-out duration-700">
                <li className={` hover:text-gray-300 text-[#30313c]`}>
                  <Link to="/#home">서비스 소개</Link>
                </li>
                <li className={` hover:text-gray-300 text-[#30313c]`}>
                  <Link to="/serviceIntro">기능 소개</Link>
                </li>
                
              </ul> */}

              <div className="mt-3 space-y-2 md:hidden transform ease-in-out duration-700 ">
                <Link to="/inquiries">
                  <div className="inline-block w-full px-0 py-2 text-center text-white bg-[#30313c] rounded-md shadow  hover:text-gray-300">
                    문의하기
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Paper>
      </nav>
    </>
  );
}

import React from "react";
import footerTopImage from "../img/components/body/footerTopImage.png";
import { Link } from "react-router-dom";

export default function RegisterInquiry() {
  return (
    <div class="flex flex-col gap-4 md:gap-8 px-[5%] py-[10%] md:p-[10%] items-center">
      <div class="flex flex-col gap-2 md:gap-4 items-center">
        <p class="text-[18px] md:text-4xl font-semibold">
          ​자사몰 키울 준비 됐나요?
        </p>
        <p class="text-gray-500 text-[14px] md:text-lg font-medium">
          인센토로 구매력 높은{" "}
          <span class="text-blue-500">신규 고객을 획득하고 관리</span> 하세요!
        </p>
      </div>
      <Link to="/inquiries">
        <button class="bg-blue-500 px-4 md:px-10 py-3 rounded-3xl">
          <p class="text-white text-center text-[14px] md:text-[16px]">
            문의하기
          </p>
        </button>
      </Link>

      <img
        src={footerTopImage}
        alt="inquiry_alt"
        className=" w-1/3 aspect-auto  transform"
      />
    </div>
  );
}

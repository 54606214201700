import { Box } from "@mui/material";
import Section1Bg from "../img/landing_section1_bg.png";
import { ALL_PAGES_PADDING_X } from "../pages/PageFunctionIntro";

export default function Intro() {
  return (
    <div
      className={`flex flex-col items-start justify-start h-[220px] md:h-[800px] px-[5%] md:px-[${ALL_PAGES_PADDING_X}] py-[20px] md:py-[170px] gap-6 md:gap-10 bg-gray-50`}
      style={{
        backgroundImage: `url(${Section1Bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <p className="text-[18px] md:text-[48px] font-bold">
        <span className="text-blue-600">리퍼럴 마케팅</span>으로 자사몰을
        키우세요
      </p>
      <div className="flex flex-col gap-1">
        <p className="md:text-[28px]">리퍼럴 마케팅으로</p>
        <p className="text-[14px] md:text-[28px]">
          <span className="font-bold">구매력 높은 고객 획득부터 관리</span>까지
        </p>
        <p className="md:text-[28px]">인센토로 해결하세요.</p>
      </div>
    </div>
  );
}

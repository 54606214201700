import React, { useRef, useState } from "react";
import logo from "../Assets/img-logo.png";
import "../styles/pages/forgot/reset.css";
import script from "../scripts/login.json";
import arrowWhite from "../Assets/arrow_blue.svg";
import arrowBlack from "../Assets/ic-arrow-right.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

export default function ResetPassword() {
  const [confirm, setConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [after, setAfter] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      submitForm(e);
    }
  }
  let { token } = useParams();

  return (
    <div className="forgot__container">
      <div className="main__container">
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <div className="logo__container">
            <img src={logo} alt="" />
          </div>
        </Link>
      </div>
      {after ? (
        error ? (
          <div className="inputs">
            <label htmlFor="password">
              비밀 설정 중에 오류가 생겼습니다. 오류가 계속 생기면 인센토 팀에게
              문의하세요.{" "}
            </label>
            <Link to="/forgot">
              <button className="login__btn">다시하기</button>
            </Link>
          </div>
        ) : (
          <div className="inputs">
            <label htmlFor="password">비밀번호가 재설정되었습니다 </label>
            <Link to="/login">
              <button className="login__btn">로그인하기</button>
            </Link>
          </div>
        )
      ) : (
        <div className="inputs">
          <label htmlFor="password">비밀번호를 재설정합니다</label>
          <div className="password__field field">
            <input
              onKeyDown={handleKeyDown}
              type={type}
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              id="password"
              placeholder={script.fields[1][1]}
            />

            {type === "password" ? (
              password.length > 0 ? (
                <AiOutlineEyeInvisible
                  className="eye__icon"
                  style={{ color: "#9ec2fd", fontSize: "20px" }}
                  onClick={(e) => {
                    setType("text");
                  }}
                />
              ) : (
                <></>
              )
            ) : password.length > 0 ? (
              <AiOutlineEye
                className="eye__icon"
                style={{ color: "#9ec2fd", fontSize: "20px" }}
                onClick={(e) => {
                  setType("password");
                }}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="password__field field">
            <input
              onKeyDown={handleKeyDown}
              type={type}
              name="confirm"
              value={confirm}
              onChange={(e) => {
                setConfirm(e.target.value);
              }}
              id="confirm"
              placeholder={"비밀번호를 다시 한번 입력해주세요"}
            />

            {type === "password" ? (
              password.length > 0 ? (
                <AiOutlineEyeInvisible
                  className="eye__icon"
                  style={{ color: "#9ec2fd", fontSize: "20px" }}
                  onClick={(e) => {
                    setType("text");
                  }}
                />
              ) : (
                <></>
              )
            ) : password.length > 0 ? (
              <AiOutlineEye
                className="eye__icon"
                style={{ color: "#9ec2fd", fontSize: "20px" }}
                onClick={(e) => {
                  setType("password");
                }}
              />
            ) : (
              <></>
            )}
          </div>
          <button className="login__btn" onClick={submitForm}>
            재설정하기
          </button>
        </div>
      )}
    </div>
  );

  function submitForm(e: any) {
    if (!confirm || !password) {
      alert("모든 항목을 입력해주세요");
    } else if (confirm !== password) {
      alert("비밀번호가 일치하지 않습니다");
    } else {
      let backend =
        process.env.REACT_APP_SERVER_BASE_PATH +
        "account/password_reset/confirm/";
      let data = {
        password: password,
        token: token,
      };
      axios
        .post(backend, data)
        .then((response) => {
          setAfter(true);
        })
        .catch((err) => {
          setAfter(true);
          setError(true);
        });
    }
  }
}

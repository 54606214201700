import React, { useState } from "react";
import logo from "../Assets/incento_cropped.png";
import { Link } from "react-router-dom";
import arrow_gray from "../Assets/arrow_gray.svg";
import { BsCheck } from "react-icons/bs";
import script from "../scripts/agree.json";
import "../styles/pages/agree/agree.css";
import { useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

export default function Agree() {
  const [list, setList] = useState<number[]>([]);
  const [notAll, setNotAll] = useState(false);
  React.useEffect(() => {
    // setList(Catalogues);
  }, [list]);

  const handleSelect = (num: number) => {
    if (list.includes(num)) {
      setList(
        list.filter((value, index) => {
          return value !== num && value !== -1;
        })
      );
      if (num === -1) {
        setList([]);
      }
    } else {
      if ((list.length === 2 && !list.includes(-1)) || num === -1) {
        setList([-1, 1, 2, 3]);
      } else {
        setList([...list, num]);
      }
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    if (!list.includes(-1)) {
      setNotAll(true);
    } else {
      setNotAll(false);
    }
  };

  return (
    <div className="h-screen bg-gradient-to-r from-blue-500 to-blue-200 overflow-x-hidden">
      <div className="w-screen mx-auto pb-5">
        <NavBar />
      </div>
      <div className=" w-full  py-20 flex justify-center items-center">
        <div className="flex flex-col justify-start items-center w-full h-[40rem] max-w-[32rem] bg-white/30 rounded-lg">
          {/* -----ist------ */}
          <div className="flex flex-col justify-center items-center w-full py-[50px]  gap-y-2">
            <div className="flex justify-center items-center w-full px-40">
              <div className="bg-white text-white rounded-full p-4 relative flex justify-center items-center">
                <span className="absolute">
                  <BsCheck className="h-7 w-7 text-blue-200/80" />
                </span>
              </div>
              <div className="bg-white/40  px-8 py-[2px]"></div>

              <div className="bg-white/40  rounded-full p-3 border-[5px] border-white"></div>
            </div>
            <div className="flex w-full justify-center items-center gap-x-[52px] ">
              <div className="text-white text-xs">{script.path[0]}</div>
              <div className="text-white text-xs">{script.path[1]}</div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center w-full pt-7 gap-y-2">
            {notAll && <p className="not_all__msg">{script.error}</p>}
            {/* ------ist---- */}

            <div className="flex justify-center  items-center w-full px-7 md:px-20 ">
              <div className="flex justify-start   items-center w-full bg-white/60 rounded-2xl py-2 relative ">
                <div className="absolute left-3.5 top-1.5 ">
                  <input
                    onChange={() => handleSelect(-1)}
                    type="checkbox"
                    id="all"
                    name="yes"
                    checked={isChecked(-1)}
                    className=" h-5 w-5 appearance-none border bg-white border-blue-300 rounded-[4px]  accent-slate-500 focus:ring-transparent  "
                  />
                </div>

                <div className="text-base  pl-12 text-[#30313c]">
                  {script.all_agree}
                </div>
              </div>
            </div>
            {/* ------2nd---- */}
            <div className="flex justify-center  items-center w-full px-7 md:px-20 ">
              <div className="flex justify-start   items-center w-full   py-2 relative ">
                <div className="absolute left-3.5 top-1.5">
                  <input
                    onChange={() => handleSelect(1)}
                    type="checkbox"
                    id="all"
                    name="yes"
                    checked={isChecked(1)}
                    className=" h-5 w-5 appearance-none border bg-transparent border-white rounded-[4px]  accent-slate-500 focus:ring-transparent "
                  />
                </div>
                <div className="text-base  pl-12 font-bold text-[#30313c]">
                  <div dangerouslySetInnerHTML={{ __html: script.first }}></div>
                </div>
              </div>
            </div>
            {/* ------3rd---- */}
            <div className="flex justify-center  items-center w-full px-7 md:px-20 ">
              <div className="flex justify-start   items-center w-full   py-2 relative ">
                <div className="absolute left-3.5 top-1.5">
                  <input
                    onChange={() => handleSelect(2)}
                    type="checkbox"
                    id="all"
                    name="yes"
                    checked={isChecked(2)}
                    className=" h-5 w-5 appearance-none border bg-transparent border-white rounded-[4px]  accent-slate-500 focus:ring-transparent"
                  />
                </div>
                <div className="text-base font-bold  pl-12 text-[#30313c]">
                  <div
                    dangerouslySetInnerHTML={{ __html: script.second }}
                  ></div>
                </div>
              </div>
            </div>
            {/* ------4th---- */}
            <div className="flex justify-center  items-center w-full px-7 md:px-20 ">
              <div className="flex justify-start   items-center w-full   py-2 relative ">
                <div className="absolute left-3.5 top-1.5">
                  <input
                    onChange={() => handleSelect(3)}
                    type="checkbox"
                    id="all"
                    name="yes"
                    checked={isChecked(3)}
                    className=" h-5 w-5 appearance-none border bg-transparent border-white rounded-[4px]  accent-slate-500 focus:ring-transparent  "
                  />
                </div>
                <div className="text-base  pl-12 text-[#30313c]">
                  {script.third}
                </div>
              </div>
            </div>
            <div className="pt-7  w-full px-7 md:px-20">
              <Link to="/register/success">
                <button
                  className=" font-bold w-full px-6 py-2.5 text-md transition  duration-150
				            ease-in-out text-white bg-blue-500 rounded-xl shadow hover:bg-gray-100 hover:text-[#30313c]"
                  onClick={handleSubmit}
                >
                  가입하기
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* -----------------------old--------------- */}
      {/* 
      <div className=" bg-[#f3fafd]">
        <div className="">
          <div className="agree__container">
            <main className="agree_main">
              <div className="path">
                <p className="path_cur">{script.path[0]}</p>
                <img src={arrow_gray} alt="" />
                <p className="path_next">{script.path[1]}</p>
              </div>

              <p className="agree_title">{script.title}</p>

              <div className="fields__sign">
                {notAll && <p className="not_all__msg">{script.error}</p>}
                <div className="fields">
                  <div>
                    <input
                      onChange={() => handleSelect(-1)}
                      type="checkbox"
                      id="all"
                      name="yes"
                      checked={isChecked(-1)}
                    />
                    <label htmlFor="all">{script.all_agree}</label>
                  </div>
                  <div>
                    <input
                      onChange={() => handleSelect(1)}
                      type="checkbox"
                      id="all"
                      name="yes"
                      checked={isChecked(1)}
                    />
                    <label htmlFor="all">
                      <div
                        dangerouslySetInnerHTML={{ __html: script.first }}
                      ></div>
                    </label>
                  </div>
                  <div>
                    <input
                      onChange={() => handleSelect(2)}
                      type="checkbox"
                      id="all"
                      name="yes"
                      checked={isChecked(2)}
                    />
                    <label htmlFor="all">
                      <div
                        dangerouslySetInnerHTML={{ __html: script.second }}
                      ></div>
                    </label>
                  </div>
                  <div>
                    <input
                      onChange={() => handleSelect(3)}
                      type="checkbox"
                      id="all"
                      name="yes"
                      checked={isChecked(3)}
                    />
                    <label htmlFor="all">{script.third}</label>
                  </div>
                </div>
                <div className="sign">
                  <Link
                    to="/register/success"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="join_btn" onClick={handleSubmit}>
                      가입하기
                    </button>
                  </Link>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div> */}
      <div className="w-screen mx-auto py-4 flex justify-center bg-white ">
        <Footer />
      </div>
    </div>
  );

  function isChecked(num: number) {
    return list.includes(-1) || list.includes(num);
  }
}

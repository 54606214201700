import { Box, Button, Paper, Typography } from "@mui/material";

export interface SliderPaperProps {
  buttonText: string;
  buttonColor: string;
  body: string[];
  imgUrl: string;
  bgColor: string;
}

export default function SliderPaper({
  bgColor,
  body,
  buttonColor,
  buttonText,
  imgUrl,
}: SliderPaperProps) {
  return (
    <Box
      className={`flex flex-col gap-4 justify-center items-center p-4 md:p-8 w-full h-[350px] md:h-[600px] rounded-2xl`}
      style={{ backgroundColor: bgColor }}
    >
      <Box className="flex flex-col justify-start items-center h-1/3 gap-2">
        <Button
          style={{ backgroundColor: buttonColor }}
          variant="contained"
          className="w-fit"
        >
          <p>{buttonText}</p>
        </Button>
        <Box>
          {body.map((text) => (
            <p className="flex justify-center text-[13px] md:text-[22px]">
              {text}
            </p>
          ))}
        </Box>
      </Box>
      <Box className="h-2/3">
        <img src={imgUrl} alt={buttonText} className="w-full h-full" />
      </Box>
    </Box>
  );
}

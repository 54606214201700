import "../styles/pages/connect/details.css";
import DetailsOne from "../components/details/DetailsOne";
import DetailsTwo from "../components/details/DetailsTwo";
import DetailsThree from "../components/details/DetailsThree";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import PageLayout from "../components/PageLayout";
export default function CustomerDetails() {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [shopUrl, setShopUrl] = useState("");
  const [gmvType, setGmvType] = useState("DEF");
  const [shopType, setShopType] = useState("DEF");
  const [hostingType, setHostingType] = useState("DEF");
  const [hostingID, setHostingID] = useState("");
  const [create, setCreate] = useState(false);

  useEffect(() => {
    if (create) {
      var access_token;
      var refresh_token;
      var access_token_expiration_timestamp;
      var refresh_token_expiration_timestamp;
      var key;
      var access;
      var shop_no;
      var new_register;

      var cookie_entries = document.cookie.split("; ");
      for (var j = 0; j < cookie_entries.length; j++) {
        if (cookie_entries[j].includes("access=")) {
          [key, access] = cookie_entries[j].split("=");
        }
        if (cookie_entries[j].includes("access_token=")) {
          [key, access_token] = cookie_entries[j].split("=");
        }
        if (cookie_entries[j].includes("refresh_token=")) {
          [key, refresh_token] = cookie_entries[j].split("=");
        }
        if (cookie_entries[j].includes("access_token_expiration_timestamp=")) {
          [key, access_token_expiration_timestamp] =
            cookie_entries[j].split("=");
        }
        if (cookie_entries[j].includes("refresh_token_expiration_timestamp=")) {
          [key, refresh_token_expiration_timestamp] =
            cookie_entries[j].split("=");
        }
        if (cookie_entries[j].includes("shop_no=")) {
          [key, shop_no] = cookie_entries[j].split("=");
        }
        if (cookie_entries[j].includes("new_register=")) {
          [key, new_register] = cookie_entries[j].split("=");
        }
      }

      let config = {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + access,
          "Content-Type": "application/json",
        },
      };

      let data = {
        cafe24_id: hostingID,
        name: name,
        logo_url: shopUrl,
        shop_url: shopUrl,
        has_active_campaign: false,
        hosting_type: hostingType,
        hosting_id: hostingID,
        gmv_type: gmvType,
        ceo_name: "Sung Jun Hong",
        ceo_email: "sjtheorange@gmail.com",
        ceo_number: "01042842464",
        access_token: access_token,
        refresh_token: refresh_token,
        access_token_expiration_timestamp: access_token_expiration_timestamp,
        refresh_token_expiration_timestamp: refresh_token_expiration_timestamp,
        shop_no: shop_no,
        new_register: new_register,
      };
      axios
        .post(
          process.env.REACT_APP_SERVER_BASE_PATH + "shop/create/",
          data,
          config
        )
        .then((response) => {
          var response_key, response_access;
          var cookie_entries = document.cookie.split("; ");
          for (var j = 0; j < cookie_entries.length; j++) {
            if (cookie_entries[j].includes("access=")) {
              [response_key, response_access] = cookie_entries[j].split("=");
            }
          }
          let sid = response.data.sid;
          let config = {
            headers: {
              accept: "application/json",
              Authorization: "Bearer " + response_access,
              "Content-Type": "application/json",
            },
          };
          let data = {
            shop: response.data.sid,
          };
          document.cookie = "shop=" + sid + ";domain=.incento.kr";
          window.location.href = "https://dashboard.incento.kr";
        })
        .catch((e) => {
          console.log(e);
          alert("오류4");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  return (
    <PageLayout>
      <div className="h-screen w-screen bg-gradient-to-r from-blue-500 to-blue-200 overflow-x-hidden">
        {/* <div className="w-screen mx-auto pb-5">
        <NavBar
          navbarbgColour="bg-white"
          navbarColour={true}
          blackText="text-[#30313c]"
          whiteText="text-white"
        />
      </div> */}
        <div className="flex justify-center items-center w-full h-full  ">
          <div className="flex flex-col justify-center items-center w-full ">
            {/* <div className="w-full flex justify-center items-center">
            <Link to={"/"}>
              <img src={imgLogo} alt="Logo" />
            </Link>
          </div> */}

            {step === 1 ? (
              <DetailsOne
                setStep={setStep}
                name={name}
                setName={setName}
                shopUrl={shopUrl}
                setShopUrl={setShopUrl}
              />
            ) : step === 2 ? (
              <DetailsTwo
                setStep={setStep}
                shopType={shopType}
                setShopType={setShopType}
                gmvType={gmvType}
                setGmvType={setGmvType}
              />
            ) : (
              <DetailsThree
                setStep={setStep}
                hostingType={hostingType}
                setHostingType={setHostingType}
                hostingID={hostingID}
                setHostingID={setHostingID}
                setCreate={setCreate}
              />
            )}
          </div>
        </div>
        {/* <div className="w-screen mx-auto py-4 flex justify-center bg-white ">
        <Footer />
      </div> */}
      </div>
    </PageLayout>
  );
}

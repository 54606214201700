import { Box, Typography } from "@mui/material";
import { ALL_PAGES_PADDING_X } from "../pages/PageFunctionIntro";
import icon1 from "../img/icons_1.png";
import icon2 from "../img/icons_2.png";
import icon3 from "../img/icons_3.png";
import icon4 from "../img/icons_4.png";
import WhyIncentoPaper, { WhyIncentoPaperProps } from "./WhyIncentoPaper";

const PAPER_PROPS: WhyIncentoPaperProps[] = [
  {
    iconUrl: icon1,
    title: "고객 획득 비용 줄이기",
    body: ["점점 높아지는 고객 획득 비용을", "리퍼럴 마케팅을 통해 낮추세요"],
  },
  {
    iconUrl: icon2,
    title: "구매력 높은 고객 획득",
    body: [
      "리퍼럴 마케팅은 구매력",
      "높은 고객을 획득 할 수 있는",
      "가장 효과적인 방법 입니다",
    ],
  },
  {
    iconUrl: icon3,
    title: "VIP 고객 관리",
    body: ["리퍼럴에 적극적인 고객들을", "트래킹하고 관리 할 수 있습니다"],
  },
  {
    iconUrl: icon4,
    title: "자사몰 키우기",
    body: ["자사몰의 매출을 높여 오픈마켓", "의존도를 낮추세요"],
  },
];

export default function WhyIncento() {
  return (
    <Box
      className={`w-full flex flex-col px-[5%] md:px-[${ALL_PAGES_PADDING_X}] py-[30px] md:py-[100px] gap-4 md:gap-12 bg-gray-50`}
    >
      <Box>
        <p className="flex justify-center pb-4 text-[18px] md:text-[30px] font-bold">
          인센토가 &nbsp; <span className="text-blue-600">왜</span> &nbsp;
          필요한가요?
        </p>
        <p className="flex justify-center text-[14px] md:text-[16px]">
          높은 광고비용 대신에 이제 낮은 리퍼럴 마케팅 비용으로
        </p>
        <p className="flex justify-center text-[14px] md:text-[16px]">
          구매력 높은 고객을 획득하실 수 있습니다.
        </p>
      </Box>
      <Box className="flex flex-wrap gap-6 justify-center">
        {PAPER_PROPS.map(({ body, iconUrl, title }) => (
          <WhyIncentoPaper body={body} iconUrl={iconUrl} title={title} />
        ))}
      </Box>
    </Box>
  );
}

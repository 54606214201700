import { useEffect } from "react";
import imgStartup from "../img/pagePrice/Startup_Two Color.png";
import imgData from "../img/pagePrice/Data Arranging_Two Color.png";
import imgCard from "../img/pagePrice/card@4x.png";
import PageLayout from "../components/PageLayout";
import { Link } from "react-router-dom";

const PagePrice = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <PageLayout>
       
        <div className=" w-full min-h-[1080px] max-h-max flex flex-col justify-center items-center relative bg-[#f3fafd]">
          <div className="w-full max-w-[1920px]">
            <div className="flex flex-col justify-center items-center gap-2 mt-32 mb-8 md:mt-5">
              <div className="text-4xl font-bold text-gray-800 ">
                인센토 구독료
              </div>
              <div className="tracking-wide text-gray-800 mt-2">
                서비스 구독료는 고객 수에 따라 책정됩니다.
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-center items-center w-full md:p-14 gap-y-5 md:gap-y-0 md:gap-x-5 ">
              <div className="w-full md:w-1/2">
                <div className="bg-white rounded-lg drop-shadow-lg flex flex-col gap-y-4 px-10 pt-12 md:h-[330px]">
                  <div className="flex flex-col justify-center items-center md:items-start gap-y-2">
                    <div className="text-xl font-bold text-gray-800">
                      고객 1만 명
                    </div>
                    <div className="text-gray-500">
                      1만 명까지 월 10만 원입니다.
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between items-center mt-16 md:mt-5">
                    <div className="text-3xl font-bold text-gray-800">
                      월 10만 원
                    </div>
                    <div className="">
                      <img src={imgStartup} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2  ">
                <div className="bg-white rounded-lg drop-shadow-lg  flex flex-col gap-y-[65px]   px-10 pt-10 md:h-[330px]">
                  <div className="flex flex-col justify-center items-center md:items-start gap-y-1">
                    <div className="text-xl font-bold text-sky-600 relative flex justify-start items-center">
                      <span className="absolute -top-0.3 ">+</span>
                      <span className="pl-4">고객 1만 명 당</span>
                    </div>
                    <div className="text-gray-500 text-sm">
                      1만 명 고객 이상의 고객은 1만 명당
                    </div>
                    <div className="text-gray-500 text-sm">
                      5만 원의 추가 구독료가 발생합니다
                    </div>
                    <div className="text-gray-500 text-sm">
                      예: *고객 2만 명: 월 구독료 15만 원
                    </div>
                    <div className="text-gray-500 text-sm">
                      *고객 3만 명: 월 구독료 20만 원
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row  justify-between items-center relative ">
                    <div className="">
                      <span className="text-3xl font-bold text-gray-800">
                        5만 원
                      </span>
                      <span className="text-gray-600 text-sm pl-1">
                        / 고객수 1만 명 당
                      </span>
                    </div>
                    <div className="md:absolute md:-right-6 md:-top-24">
                      <img src={imgData} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-center items-center gap-x-5 py-10 gap-y-3 md:gap-y-0 hidden">
              <div>
                <img src={imgCard} alt="Logo" width={50} />
              </div>
              <div className="text-gray-800 text-xl md:text-2xl">
                카드 정보 없이, 30일 무료 시작
              </div>
              <Link to="/connect/register">
                <div className="px-8 py-2 flex justify-center shadow items-center bg-white rounded-lg text-gray-800 cursor-pointer font-bold ring ring-white hover:ring-gray-200 hover:shadow-md hover:shadow-gray-300 active:ring-white ">
                  무료로 시작하기
                </div>
              </Link>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default PagePrice;

import React from "react";
import "../../styles/components/home/footer/footer.css";
import script from "../../scripts/home/footer.json";

export default function Footer() {
  return (
    <div className="footer__container" id="footer">
      <div className="footer_main">
        <div className="footer_left">
          <div className="first_column column">
            {Object.keys(script.columns[0]).map((key, i) => (
              <div key={i + 100} className="row">
                <p>{key}</p>
                <p>
                  {script.columns[0][key as keyof (typeof script.columns)[0]]}
                </p>
              </div>
            ))}
          </div>
          <div className="second_column column">
            {Object.keys(script.columns[1]).map((key, i) => (
              <div key={i + 1000} className="row">
                <p>{key}</p>
                <p>
                  {script.columns[1][key as keyof (typeof script.columns)[1]]}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="footer_right column">
          <a href="/terms">
            <p>{script.info[0]}</p>
          </a>
          <a href="/privacy">
            <p>{script.info[1]}</p>
          </a>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "../../styles/components/details/detailsThree/detailsThree.css";
import arrow_left from "../../Assets/ic-right.svg";
import check from "../../Assets/check.svg";

type props = {
  setStep: Function;
  hostingType: string;
  setHostingType: Function;
  hostingID: string;
  setHostingID: Function;
  setCreate: Function;
};

export default function DetailsOne({
  setStep,
  hostingType,
  setHostingType,
  hostingID,
  setHostingID,
  setCreate,
}: props) {
  const handleHostingTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setHostingType(event.target.value);
  };
  const handleHostingIDChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHostingID(event.target.value);
  };
  var key;
  var mall_id;

  var cookie_entries = document.cookie.split("; ");
  for (var j = 0; j < cookie_entries.length; j++) {
    if (cookie_entries[j].includes("mall_id=")) {
      [key, mall_id] = cookie_entries[j].split("=");
      setHostingID(mall_id);
      setHostingType("CF");
    }
  }

  return (
    <div className="details_content_three">
      <div className="details_step">
        <div className="details_step_first">
          <img src={check} alt="done" />
        </div>
        <div className="details_step_divider"></div>
        <div className="details_step_second">
          <img src={check} alt="done" />
        </div>
        <div className="details_step_divider"></div>
        <div className="details_step_third"></div>
      </div>
      <p className="details_title">자사몰 정보를 입력하세요</p>
      <div className="flex items-center justify-center">
        <div className="w-full max-w-[23rem] mt-5 flex flex-col gap-5">
          <select
            className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
            defaultValue={mall_id ? "CF" : "DEF"}
            disabled={mall_id ? mall_id.length > 0 : false}
            onChange={handleHostingTypeChange}
            value={hostingType}
          >
            <option value="DEF" disabled>
              사이트 호스팅 사를 선택해주세요
            </option>
            <option value="CF">카페24 ( cafe24 )</option>
            <option value="IW">아임웹 ( imweb )</option>
            <option value="FM">퍼스트몰 ( firstmall )</option>
            <option value="GM">고도몰 ( godomall )</option>
            <option value="MS">메이크샵 ( makeshop )</option>
            <option value="SS">식스샵 ( sixshop )</option>
            <option value="ID">기타</option>
          </select>
          <input
            className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
            placeholder="호스팅사의 아이디가 있다면 입력해주세요"
            value={mall_id ? mall_id : hostingID}
            disabled={mall_id ? mall_id.length > 0 : false}
            onChange={handleHostingIDChange}
          ></input>
          {/* <button onClick={createShop}> */}
          <button
            className="font-bold w-full px-6 py-2.5  text-md transition  duration-150
				   ease-in-out text-white bg-blue-500 rounded-md shadow hover:bg-gray-100 hover:text-gray-800"
            onClick={() => {
              setCreate(true);
            }}
          >
            시작하기
          </button>
          <div
            onClick={() => {
              setStep(2);
            }}
            className="flex flex-row"
          >
            <img src={arrow_left} alt="back" />
            <div className="text-gray-900 mt-0.5">이전 단계로</div>
          </div>
        </div>
      </div>
    </div>
  );
}

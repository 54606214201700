import { Link } from "react-router-dom";
import incentoLogo from "../img/로고2.png";

export default function Footer() {
  return (
    <footer className="w-full md:p-6">
      <div class="flex flex-col gap-4 md:gap-10 bg-inherit py-4 px-[5%] md:pl-[10%]">
        <div className="">
          <img
            src={incentoLogo}
            alt="Logo"
            className="w-[120px] md:w-[180px]"
          />
        </div>
        <div class="flex flex-col gap-1 text-gray-300 text-[12px] md:text-[16px]">
          <p class="">
            상호명 : 오픈포맷 주식회사 | 대표자 : KIM THOMAS SUNGJIN |
            사업자등록번호 : 823-86-02119
          </p>
          <p class="">
            통신판매업신고번호 : 2023-서울서초-2570 | 대표번호 : 010-7117-5145 |
            주소 : 서울특별시 서초구 방배로 180 (방배동) 309호
          </p>
        </div>

        <p class="text-gray-300 text-[12px] md:text-[16px]">
          <Link to="/terms">
            <span className="hover:text-blue-500">서비스 이용약관 | </span>
          </Link>
          <Link to="/privacy">
            {" "}
            <span className="hover:text-blue-500">개인정보 처리방침</span>
          </Link>
        </p>
      </div>
    </footer>
  );
}

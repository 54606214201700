import React, { useState, useEffect } from "react";
import LikeIcon from "../Assets/png/ThumbIcon.png";
import HeartIcon from "../Assets/png/HeartIcon.png";
import FriendIcon from "../Assets/png/FriendIcon.png";

const ServiceIntroduction = ({ activeIndex }) => {
  const [visibleIndexes, setVisibleIndexes] = useState([activeIndex]);

  useEffect(() => {
    setVisibleIndexes([activeIndex]);
  }, [activeIndex]);

  return (
    <div class="flex flex-col items-end gap-6 p-4">
      <p class=" text-gray-500 font-medium ">기능 소개</p>
      <div class="gap-2 flex flex-col items-end text-[18px] md:text-3xl font-semibold">
        <p class="">
          <span class="text-blue-500">5분 </span>안에 가장 효과적인
        </p>
        <p class="">리퍼럴 마케팅으로</p>
        <p class="">
          <span class="text-blue-500">신규 고객을 획득 </span> 하세요.
        </p>
      </div>

      <div class="flex flex-col items-end">
        <p class="text-[14px] md:text-[16px] text-gray-500">
          리퍼럴 하는 고객은 자사몰 성장에 가장 크게
        </p>
        <p class="text-base text-gray-500">공헌하는 분들 입니다.</p>
      </div>

      <div className="flex flex-col items-end h-[252px]">
        {/* Render divs conditionally based on visibility state */}

        {visibleIndexes.includes(0) && (
          <div class="mt-4 flex  flex-col items-end gap-10">
            <div class="flex flex-col items-end">
              <div className="flex items-start gap-2">
                <img src={LikeIcon} alt="" className="h-6 md:h-8" />
                <p class="pb-1 text-[18px] md:text-2xl font-semibold">
                  리퍼럴 리워드 설정
                </p>
              </div>

              <p class="text-[14px] font-light text-gray-300">
                추천인과 피추천인에게 지급할 쿠폰과 포인트의 금액
              </p>
              <p class="text-[14px] font-light text-gray-300">
                밎 지급 시점을 커스텀 할 수 있습니다.
              </p>
            </div>

            <div class="text-gray flex flex-col gap-10 items-end">
              <div className="flex items-start gap-2">
                <img
                  src={HeartIcon}
                  alt=""
                  className="h-6 md:h-8 opacity-20 "
                />
                <p class="pb-2 text-[18px] md:text-2xl font-semibold text-gray-100">
                  리퍼럴 위젯 설정
                </p>
              </div>
            </div>
          </div>
        )}
        {visibleIndexes.includes(1) && (
          <div class="mt-4 flex flex-col items-end gap-10">
            <div class="flex flex-col items-end">
              <div className="flex items-start gap-2">
                <img src={LikeIcon} alt="" className="h-6 md:h-8 opacity-20" />
                <p class="pb-1 text-[18px] md:text-2xl font-semibold text-gray-100">
                  리퍼럴 리워드 설정
                </p>
              </div>
            </div>

            <div class="flex flex-col items-end">
              <div className="flex items-start gap-2">
                <img src={HeartIcon} alt="" className="h-6 md:h-8" />
                <p class="pb-1 text-[18px] md:text-2xl font-semibold">
                  리퍼럴 위젯 설정
                </p>
              </div>

              <p class="text-sm font-light text-gray-300">
                내 자사몰에 어울리게 리퍼럴 위젯의
              </p>
              <p class="text-sm font-light text-gray-300">
                디자인과 위치를 커스텀 할 수 있습니다.
              </p>
            </div>
          </div>
        )}
        {visibleIndexes.includes(2) && (
          <div class="mt-4  flex flex-col items-end gap-10">
            <div class=" flex flex-col items-end gap-10">
              <div className="flex items-start gap-2">
                <img src={LikeIcon} alt="" className="h-6 md:h-8 opacity-20 " />
                <p class="pb-2 text-[18px] md:text-2xl font-semibold text-gray-100">
                  리퍼럴 리워드 설정
                </p>
              </div>

              <div className="flex items-start gap-2">
                <img
                  src={HeartIcon}
                  alt=""
                  className="h-6 md:h-8 opacity-20 "
                />
                <p class="pb-2 text-[18px] md:text-2xl font-semibold text-gray-100">
                  리퍼럴 위젯 설정
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceIntroduction;

import React, { useState } from "react";
import "../styles/pages/signup/signup.css";
import logo from "../Assets/incento_cropped.png";
import arrow_next from "../Assets/arrow-right-line (3).svg";
import arrow_gray from "../Assets/arrow_gray.svg";
import Navbar from "../components/home/Navbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "../components/home/Footer";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import script from "../scripts/home/signup.json";
import axios from "axios";
import { Widget } from "@typeform/embed-react";

export default function Signup() {
	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [number, setNumber] = useState("");
	const [contact_method, setPreferred] = useState("PHONE");
	let navigate = useNavigate();

	const [useNumber, setUseNumber] = useState(false);
	const [useEmail, setUseEmail] = useState(false);
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [type, setType] = useState("password");
	const [wrong, setWrong] = useState(false);

	return (
		<div className="register__container">
			<Navbar />
			<div className="register_body">
				<p className="register_title">{script.title}</p>
				<div className="fields__sign">
					<div className="fields">
						{/* <Widget id="OSTsfOEe" className="my_form" /> */}
						<div className="name__field field">
							<label htmlFor="name">{script.name[0]}</label>
							<input
								type="text"
								value={username}
								onChange={(e) => {
									setUsername(e.target.value);
								}}
								name="name"
								id="name"
								placeholder={script.name[1]}
							/>
						</div>
						<div className="email__field field">
							<div className="email__top">
								<label htmlFor="email">{script.email[0]}</label>
							</div>

							<input
								type="email"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								name="email"
								id="email"
								placeholder={script.email[1]}
							/>
						</div>
						<div className="number__field field">
							<label htmlFor="number">{script.number[0]}</label>
							<input
								type="text"
								value={number}
								onChange={(e) => {
									setNumber(e.target.value);
								}}
								name="number"
								id="number"
								placeholder={script.number[1]}
							/>
						</div>
						<div className="type__field checkbox__fields">
							<label htmlFor="useNumber">{script.type[0]}</label>
							<div className="checkbox_field">
								<div className="checkbox">
									<input
										type="checkbox"
										// value={this.state.checked}
										checked={contact_method === "PHONE"}
										onChange={(e) => {
											if (contact_method === "PHONE") {
												setPreferred("");
											} else {
												setPreferred("PHONE");
											}
										}}
										name="useNumber"
										id="useNumber"
									/>
									<label> {script.type[1]}</label>
								</div>
								<div className="checkbox">
									<input
										type="checkbox"
										onChange={(e) => {
											if (contact_method === "EMAIL") {
												setPreferred("");
											} else {
												setPreferred("EMAIL");
											}
										}}
										checked={contact_method === "EMAIL"}
										name="useNumber"
										id="useNumber"
									/>
									<label> {script.type[2]}</label>
								</div>
							</div>
						</div>
					</div>
					{/* <Link to="/signup/success"> */}
					<div className="sign" onClick={submitForm}>
						<img src={arrow_next} alt="" />
					</div>
					{/* </Link> */}
				</div>
			</div>

			{/* <Footer /> */}
		</div>
	);

	function submitForm(e: any) {
		if (!username || !email || !number) {
			alert("Please enter all fields");
		} else {
			let backend = "https://api.incento.kr/api/v1/signups/register/"
			axios
				.post(backend, { email, username, number, contact_method })
				.then((response) => {
					console.log(response);
					navigate("/signup/success");
				})
				.catch((e) => {
					alert("오류가 있었습니다.");
				});
		}
	}
}

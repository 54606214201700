import React from "react";
import "../../styles/components/details/detailsTwo/detailsTwo.css";
import arrow_left from "../../Assets/ic-right.svg";
import check from "../../Assets/check.svg";

type props = {
  setStep: Function;
  shopType: string;
  setShopType: Function;
  gmvType: string;
  setGmvType: Function;
};

export default function DetailsOne({
  setStep,
  shopType,
  setShopType,
  gmvType,
  setGmvType,
}: props) {
  const handleShopTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setShopType(event.target.value);
  };
  const handleGmvTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setGmvType(event.target.value);
  };
  return (
    <div className="details_content_two">
      <div className="details_step">
        <div className="details_step_first">
          <img src={check} alt="done" />
        </div>
        <div className="details_step_divider_one"></div>
        <div className="details_step_second"></div>
        <div className="details_step_divider_two"></div>
        <div className="details_step_third">3</div>
      </div>
      <p className="details_title">자사몰 정보를 입력하세요</p>
      <div className="flex items-center justify-center">
        <div className="w-full max-w-[23rem] mt-5 flex flex-col gap-5">
          <select
            className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
            defaultValue={""}
            onChange={handleShopTypeChange}
            value={shopType}
          >
            <option value="DEF" disabled>
              업종을 선택해주세요
            </option>
            <option value="FSH">패션의류/잡화</option>
            <option value="FNB">식품</option>
            <option value="KCH">주방용품</option>
            <option value="LIF">생활용품</option>
            <option value="ELC">가전 디지털</option>
            <option value="HOB">취미</option>
            <option value="BEA">뷰티</option>
            <option value="BBY">출산/유아동</option>
            <option value="INT">홈인테리어</option>
            <option value="SPT">스포츠/레저</option>
            <option value="CAR">자동차용품</option>
            <option value="BKS">도서/음반/DVD</option>
            <option value="OFF">문구/오피스</option>
            <option value="PET">반려동물용품</option>
            <option value="HEA">헬스/건강식품</option>
            <option value="OTH">기타</option>
          </select>
          <select
            className="block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
            defaultValue={""}
            onChange={handleGmvTypeChange}
            value={gmvType}
          >
            <option value="DEF" disabled>
              월 매출을 선택해주세요
            </option>
            <option value="IND">0 ~ 500,000원</option>
            <option value="SML">500,000원 ~ 1,000,000원</option>
            <option value="GRW">1,000,000원 ~ 5,000,000원</option>
            <option value="MED">5,000,000원 ~ 10,000,000원</option>
            <option value="LRG">10,000,000원 ~ 50,000,000원</option>
            <option value="ENT">50,000,000원 이상</option>
          </select>
          <button
            className=" font-bold w-full px-6 py-2.5  text-md transition  duration-150
				   ease-in-out text-white bg-blue-500 rounded-md shadow hover:bg-gray-100 hover:text-gray-800"
            onClick={() => {
              setStep(3);
            }}
          >
            다음 단계로
          </button>
          <div
            className="flex flex-row"
            onClick={() => {
              setStep(1);
            }}
          >
            <img src={arrow_left} alt="back" />
            <div className="text-gray-900 mt-0.5">이전 단계로</div>
          </div>
        </div>
      </div>
    </div>
  );
}

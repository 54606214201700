import Home from "./pages/Home";
import Login from "./pages/Login";
import LoginDev from "./pages/LoginDev";
import Register from "./pages/Register";
import Agree from "./pages/Agree";
import Pricing from "./pages/Pricing";
import Signup from "./pages/Signup";
import Connect from "./pages/Connect";
import RegisterSuccess from "./pages/RegisterSuccess";
import RegisterConnect from "./pages/RegisterConnect";
import CustomerDetails from "./pages/CustomerDetails";
import RegisterAgree from "./pages/RegisterAgree";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Cafe24Issue from "./pages/Cafe24Issue";
import ShopSelect from "./pages/ShopSelect";
import ShopSelectDev from "./pages/ShopSelectDev";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import PageFunctionIntro from "./pages/PageFunctionIntro";
import PageServiceIntro from "./pages/PageServiceIntro";
import PagePrice from "./pages/PagePrice";
import AskDemo from "./pages/AskDemo";
import Page404 from "./pages/Page404";
import { CookiesProvider } from "react-cookie";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga";

import "./styles/fonts/fonts.css";

const App = () => {
  const TRACKING_ID = "UA-254138922-1";

  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <CookiesProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PageFunctionIntro />} />
            {/* <Route path="/home" element={<Home />} /> */}
            <Route path="login" element={<Login />} />
            <Route path="login-dev" element={<LoginDev />} />
            <Route path="register" element={<Register />} />
            <Route path="register/agree" element={<Agree />} />
            <Route path="signup/success" element={<RegisterSuccess />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="signup" element={<Signup />} />
            <Route path="connect" element={<Connect />} />
            <Route path="connect/register" element={<RegisterConnect />} />
            <Route path="connect/agree" element={<RegisterAgree />} />
            <Route path="connect/details" element={<CustomerDetails />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="cafe24issue" element={<Cafe24Issue />} />
            <Route path="select" element={<ShopSelect />} />
            <Route path="select-dev" element={<ShopSelectDev />} />
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="password/:token" element={<ResetPassword />} />
            <Route path="serviceIntro" element={<PageServiceIntro />} />
            <Route path="price" element={<PagePrice />} />
            <Route path="*" element={<PageFunctionIntro />} />
            <Route path="inquiries" element={<AskDemo />} />
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
    </>
  );
};
export default App;

import React, { useState } from "react";
import logo from "../Assets/incento_cropped.png";
import { Link } from "react-router-dom";
import arrow_gray from "../Assets/arrow_gray.svg";
import script from "../scripts/agree.json";
import "../styles/pages/agree/agree.css";
import { useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import NavBar from "../components/NavBar";
import PageLayout from "../components/PageLayout";
export default function RegisterAgree() {
  const [list, setList] = useState<number[]>([]);
  const [notAll, setNotAll] = useState(false);
  const [login, setLogin] = useState(false);

  const location = useLocation();
  const state = location.state as {
    username: string;
    password: string;
    email: string;
    number: string;
  };

  const username = state.username;
  const email = state.email;
  const number = state.number;
  const password = state.password;

  React.useEffect(() => {
    // setList(Catalogues);
  }, [list]);

  const handleSelect = (num: number) => {
    if (list.includes(num)) {
      setList(
        list.filter((value, index) => {
          return value !== num && value !== -1;
        })
      );
      if (num === -1) {
        setList([]);
      }
    } else {
      if ((list.length === 2 && !list.includes(-1)) || num === -1) {
        setList([-1, 1, 2, 3]);
      } else {
        setList([...list, num]);
      }
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    if (!list.includes(-1)) {
      setNotAll(true);
    } else {
      setNotAll(false);
    }
  };

  function openTerms() {
    window.open(
      "https://www.incento.kr/terms",
      "_blank",
      "noopener,noreferrer"
    );
  }

  function openPrivacy() {
    window.open(
      "https://www.incento.kr/privacy",
      "_blank",
      "noopener,noreferrer"
    );
  }

  return (
    <PageLayout>
      <div className="h-screen w-screen bg-gradient-to-r from-blue-500 to-blue-200 overflow-x-hidden">
        {/* <div className="w-screen mx-auto pb-5">
        <NavBar
          navbarbgColour="bg-white"
          navbarColour={true}
          blackText="text-[#30313c]"
          whiteText="text-white"
        />
      </div> */}
        <div className="flex justify-center items-center w-full h-full">
          <div className="flex flex-col justify-center items-center w-full ">
            {login && <Navigate to="/connect/details" replace={true} />}
            <div className="visible lg:invisible font-bold mt-6 text-gray-800">
              로그인은 PC환경에서 이용해주세요
            </div>
            <div className="invisible lg:visible flex flex-col ">
              <div className="flex flex-row mb-3">
                <p className="text-sm">{script.path[0]}</p>
                <img className="h-5" src={arrow_gray} alt="" />
                <p className="text-sm font-bold">{script.path[1]}</p>
              </div>

              <div className="font-bold text-lg text-white">
                {"고객" + script.title}
              </div>

              <div className="flex items-center justify-center">
                <div className="w-full max-w-[23rem] mt-[75px] flex flex-col gap-5 ">
                  {notAll && <p className="not_all__msg">{script.error}</p>}
                  <div className="flex flex-row content-center justify-start gap-2">
                    <input
                      onChange={() => handleSelect(-1)}
                      type="checkbox"
                      id="all"
                      name="yes"
                      checked={isChecked(-1)}
                      className="mt-1"
                    />
                    <label htmlFor="all">{script.all_agree}</label>
                  </div>
                  <div className="flex flex-row content-center justify-start gap-2">
                    <input
                      onChange={() => handleSelect(1)}
                      type="checkbox"
                      id="all"
                      name="yes"
                      checked={isChecked(1)}
                      className="mt-1"
                    />
                    <label htmlFor="all" onClick={openTerms}>
                      <div
                        dangerouslySetInnerHTML={{ __html: script.first }}
                      ></div>
                    </label>
                  </div>
                  <div className="flex flex-row content-center justify-start gap-2">
                    <input
                      onChange={() => handleSelect(2)}
                      type="checkbox"
                      id="all"
                      name="yes"
                      checked={isChecked(2)}
                      className="mt-1"
                    />
                    <label htmlFor="all" onClick={openPrivacy}>
                      <div
                        dangerouslySetInnerHTML={{ __html: script.second }}
                      ></div>
                    </label>
                  </div>
                  <div className="flex flex-row content-center justify-start gap-2">
                    <input
                      onChange={() => handleSelect(3)}
                      type="checkbox"
                      id="all"
                      name="yes"
                      checked={isChecked(3)}
                      className="mt-1"
                    />
                    <label htmlFor="all">{script.third}</label>
                  </div>
                  {/* <Link to="/register/success" style={{ textDecoration: "none" }}> */}
                  <button
                    className="font-bold w-full px-6 py-2.5  text-md transition  duration-150
				   ease-in-out text-white bg-blue-500 rounded-md shadow hover:bg-gray-100 hover:text-gray-800 flex flex-row content-center justify-center mt-4"
                    onClick={submitForm}
                  >
                    가입하기
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );

  function submitForm(e: any) {
    if (!list.includes(1) || !list.includes(2)) {
      alert("필수 항목은 선택해주세요");
      return;
    }
    let params = new URL(window.location.href).searchParams;
    let sid = params.get("shop_sid");
    let merchant_id = params.get("ref_id");
    let backend = process.env.REACT_APP_SERVER_BASE_PATH + "users/";

    let is_merchant = sid ? (merchant_id ? false : true) : true;

    let shop = is_merchant
      ? {}
      : {
          shop: sid,
        };

    axios
      .post(backend, {
        username,
        password,
        email,
        number,
        is_superuser: false,
        is_staff: false,
        referral_link: "",
        is_merchant: is_merchant,
        is_shop_manager: !is_merchant,
        sns_flag: list.includes(3) ? true : false,
        ...shop,
      })
      .then((response) => {
        axios
          .post(
            process.env.REACT_APP_SERVER_BASE_PATH + "account/login-merchant/",
            {
              username,
              password,
            }
          )
          .then((response) => {
            document.cookie =
              "access=" + response.data.access + ";domain=.incento.kr";
            document.cookie =
              "refresh=" + response.data.refresh + ";domain=.incento.kr";
            setLogin(true);
          })
          .catch((e) => {
            console.log(e);
            alert("서버 오류가 있었습니다");
          });
      })
      .catch((e) => {
        alert("회원 가입 문제가 있었습니다");
      });
  }

  function isChecked(num: number) {
    return list.includes(-1) || list.includes(num);
  }
}

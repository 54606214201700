import React, { useEffect, useState } from "react";
import "../styles/pages/register/register.css";

import logo from "../Assets/incento_cropped.png";
import arrow_next from "../Assets/arrow-right-line (3).svg";
import arrow_gray from "../Assets/arrow_gray.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import script from "../scripts/register.json";
import { Link } from "react-router-dom";
import validEmailIcon from "../Assets/101.svg";
import invalidEmailIcon from "../Assets/105.svg";

import PageLayout from "../components/PageLayout"

export default function Register() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");
  const [wrong, setWrong] = useState(false); // email not valid
  const [valid, setValid] = useState(false); // email valid
  const [notAll, setNotAll] = useState(false); // not all inputs filled
  const [passwordMatch, setPasswordMatch] = useState<boolean | null>(null); // passwords did not match

  useEffect(() => {
    if (password && password2) {
      if (password !== password2) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
    } else {
      setPasswordMatch(null);
    }
  }, [password, password2]);

  function checkEmailValidity(e: React.MouseEvent<HTMLButtonElement>) {
    if (valid && passwordMatch && password && password2 && !notAll && !wrong) {
    }
  }

  return (
    <PageLayout>
      <div className="h-screen bg-gradient-to-r from-blue-500 to-blue-200 overflow-x-hidden">
        {/* <div className="w-screen mx-auto pb-5">
        <NavBar
          navbarbgColour="bg-white"
          navbarColour={true}
          blackText="text-[#30313c]"
          whiteText="text-white"
        />
      </div> */}
        <div className=" w-full  py-20 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center w-full h-[40rem] max-w-[32rem] bg-white/30 rounded-lg px-7 md:px-0">
            {/* -----ist------ */}
            <div className="flex flex-col justify-center items-center w-full py-10 gap-y-2">
              <div className="flex justify-center items-center w-full px-40">
                <div className="bg-white/40  rounded-full p-3 border-[5px] border-white"></div>
                <div className="bg-white/40  px-8 py-[2px]"></div>
                <div className="bg-white/40 text-white rounded-full p-4 relative flex justify-center items-center">
                  <span className="absolute">2</span>
                </div>
              </div>
              <div className="flex w-full justify-center items-center gap-x-[52px] ">
                <div className="text-white text-xs">{script.path[0]}</div>
                <div className="text-white text-xs">{script.path[1]}</div>
              </div>
            </div>
            {/* ----2nd----- */}
            <div className="w-full max-w-[23rem] ">
              <div className="  bg-transparent block  justify-center items-center w-full ">
                <div>
                  {/* ---------------email------------- */}
                  <div className="flex flex-col justify-center items-start w-full relative ">
                    <p
                      className="absolute -top-[20px] text-sm text-[#ff7474] font-normal"
                      style={notAll ? {} : { display: "none" }}
                    >
                      {script.wrong[0]}
                    </p>
                    <div className="text-white font-bold mb-2 p-1 flex justify-between items-center w-full bg-white">
                      <span>{script.email[0]}</span>
                      <span>
                        <button
                          onClick={checkEmailValidity}
                          className={
                            isValidEmail(email)
                              ? "email_check valid_email"
                              : "email_check"
                          }
                        >
                          중복 확인 s
                        </button>
                      </span>
                    </div>
                    <div className=" mb-4 w-full">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        name="email"
                        id="email"
                        placeholder={script.email[1]}
                        className="   border border-solid border-gray-300 bg-white  w-full  px-3 py-2  text-sm font-normal text-gray-800 rounded-[17px] focus:outline-none transition ease-in-out "
                      />
                      {valid && (
                        <img
                          src={validEmailIcon}
                          className="icon_email"
                          alt="valid email icon"
                        />
                      )}
                      <p
                        className="valid_msg"
                        style={valid ? {} : { display: "none" }}
                      >
                        {script.valid}
                      </p>
                      {wrong && (
                        <img
                          src={invalidEmailIcon}
                          className="icon_email"
                          alt="invalid email icon"
                        ></img>
                      )}
                      <p
                        className="error_msg"
                        style={wrong ? {} : { display: "none" }}
                      >
                        {script.wrong[1]}
                      </p>
                    </div>
                  </div>
                  {/* ------------------name---------------- */}
                  <div className="flex flex-col justify-center items-start w-full ">
                    <div className="text-white font-bold mb-2 p-1">
                      {script.username[0]}
                    </div>
                    <div className="mb-4 relative w-full">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        name="name"
                        id="name"
                        placeholder={script.username[1]}
                        className="   px-3 w-full border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[17px] transition ease-in-out"
                      />
                    </div>
                  </div>
                  {/* ----------------password-------------- */}
                  <div className="flex flex-col justify-center items-start w-full ">
                    <div className="text-white font-bold mb-2 p-1">
                      {script.password[0]}
                    </div>
                    <div className="mb-1 relative w-full">
                      <input
                        type={type1}
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        id="password"
                        placeholder={script.password[1]}
                        className="   px-3 w-full border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[17px] transition ease-in-out"
                      />
                      <div className="absolute right-3 top-2.5">
                        <Eye
                          password={password}
                          type={type1}
                          setType={setType1}
                        />
                      </div>
                    </div>
                    <div className=" mb-1 relative w-full">
                      <input
                        type={type2}
                        name="password2"
                        value={password2}
                        onChange={(e) => {
                          setPassword2(e.target.value);
                        }}
                        id="password2"
                        placeholder={script.password[2]}
                        className=" w-full px-3 border border-solid border-gray-300 py-2 text-sm focus:outline-none font-normal text-gray-800 bg-white rounded-[17px] transition ease-in-out"
                      />
                      <div className="absolute right-3 top-2.5">
                        <Eye
                          password={password2}
                          type={type2}
                          setType={setType2}
                        />
                      </div>
                    </div>
                    <div className="flex justify-start pl-1 items-center w-full">
                      {passwordMatch === true && (
                        <p className=" text-xs text-[#3d94f9]">{script.match}</p>
                      )}
                      {passwordMatch === false && (
                        <p className=" text-xs text-[#ff7474]">
                          {script.notmatch}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="pt-10 pb-20">
                    <button
                      className=" font-bold w-full px-6 py-2.5 text-md transition  duration-150
				            ease-in-out text-white bg-blue-500 rounded-xl shadow hover:bg-gray-100 hover:text-gray-800"
                    >
                      <Link to="/register/agree">
                        <div className="">다음</div>
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------old---------- */}
        {/* <div className=" bg-[#f3fafd]">
        <div className="">
          <div className="register__container">
            <main className="register_main">
              <div className="path">
                <p className="path_cur">{script.path[0]}</p>
                <img src={arrow_gray} alt="" />
                <p className="path_next">{script.path[1]}</p>
              </div>

              <p className="register_title">{script.title}</p>

              <div className="fields__sign">
                <div className="fields">
                  <div className="email__field field">
                    <p
                      className="error_msg_top"
                      style={notAll ? {} : { display: "none" }}
                    >
                      {script.wrong[0]}
                    </p>
                    <div className="email__top">
                      <label htmlFor="email">{script.username[0]}</label>
                      <button
                        onClick={checkEmailValidity}
                        className={
                          isValidEmail(email)
                            ? "email_check valid_email"
                            : "email_check"
                        }
                      >
                        중복 확인
                      </button>
                    </div>

                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      name="email"
                      id="email"
                      placeholder={script.username[1]}
                    />
                    {valid && (
                      <img
                        src={validEmailIcon}
                        className="icon_email"
                        alt="valid email icon"
                      />
                    )}
                    <p
                      className="valid_msg"
                      style={valid ? {} : { display: "none" }}
                    >
                      {script.valid}
                    </p>
                    {wrong && (
                      <img
                        src={invalidEmailIcon}
                        className="icon_email"
                        alt="invalid email icon"
                      ></img>
                    )}
                    <p
                      className="error_msg"
                      style={wrong ? {} : { display: "none" }}
                    >
                      {script.wrong[1]}
                    </p>
                  </div>

                  <div className="name__field field">
                    <label htmlFor="name">{script.email[0]}</label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      name="name"
                      id="name"
                      placeholder={script.email[1]}
                    />
                  </div>

                  <div className="password__field field">
                    <label htmlFor="password">{script.password[0]}</label>
                    <div>
                      <input
                        type={type1}
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        id="password"
                        placeholder={script.password[1]}
                      />

                      <Eye
                        password={password}
                        type={type1}
                        setType={setType1}
                      />
                    </div>
                    <div>
                      <input
                        type={type2}
                        name="password2"
                        value={password2}
                        onChange={(e) => {
                          setPassword2(e.target.value);
                        }}
                        id="password2"
                        placeholder={script.password[2]}
                      />
                      <Eye
                        password={password2}
                        type={type2}
                        setType={setType2}
                      />
                    </div>

                    {passwordMatch === true && (
                      <p className="pass_match">{script.match}</p>
                    )}
                    {passwordMatch === false && (
                      <p className="pass_notmatch">{script.notmatch}</p>
                    )}
                  </div>
                </div>
                <Link to="/register/agree">
                  <div className="sign">
                    <img src={arrow_next} alt="" />
                  </div>
                </Link>
              </div>
            </main>
          </div>
        </div>
      </div> */}
      </div>
    </PageLayout>
  );
}

function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

type EyeProps = {
  password: string;
  type: string;
  setType: Function;
};

function Eye({ password, type, setType }: EyeProps) {
  return (
    <React.Fragment>
      {type === "password" ? (
        password.length > 0 ? (
          <AiOutlineEyeInvisible
            className="text-[#8f909c] text-xl"
            // style={{ color: "#9ec2fd", fontSize: "20px" }}
            onClick={(e) => {
              setType("text");
            }}
          />
        ) : (
          <></>
        )
      ) : password.length > 0 ? (
        <AiOutlineEye
          className="text-[#8f909c] text-xl"
          // style={{ color: "#9ec2fd", fontSize: "20px" }}
          onClick={(e) => {
            setType("password");
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import ReferralMarketingBanner from "../img/card_img_1.png";
import ReferralWidgetBanner from "../img/card_img_2.png";
import CustomerManagementBanner from "../img/card_img_3.png";

import ServiceIntroduction from "./ServiceIntroduction";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";

import { Pagination, Mousewheel, Autoplay } from "swiper";
import SliderPaper, { SliderPaperProps } from "./SliderPaper";

const SWIPER_CONTENTS: SliderPaperProps[] = [
  {
    imgUrl: ReferralMarketingBanner,
    buttonText: "리퍼럴 마케팅",
    buttonColor: "#ffa726",
    bgColor: "#fff3e0",
    body: [
      "추천인과 피추천인에게 지급할 쿠폰과",
      "포인트의 금액 및 지급 시점을 커스텀 할 수 있습니다.",
    ],
  },
  {
    imgUrl: ReferralWidgetBanner,
    buttonColor: "#66bb6a",
    bgColor: "#e8f5e9",
    body: [
      "내 자사몰에 어울리게 리퍼럴 위젯의",
      "디자인과 위치를 커스텀 할 수 있습니다.",
    ],
    buttonText: "리퍼럴 위젯",
  },
];

export default function Slider() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="flex-col lg:flex-row flex px-[5%] md:px-[10%] py-8 md:py-20 h-fit">
      <div className="w-full lg:w-1/2">
        <Swiper
          pagination={true}
          mousewheel={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          modules={[Pagination, Autoplay, Mousewheel]}
          className="mySwiper"
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        >
          {SWIPER_CONTENTS.map(
            ({ bgColor, body, buttonColor, buttonText, imgUrl }) => (
              <SwiperSlide>
                <SliderPaper
                  bgColor={bgColor}
                  body={body}
                  buttonColor={buttonColor}
                  buttonText={buttonText}
                  imgUrl={imgUrl}
                />
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>

      <div className="w-full lg:w-1/2">
        <ServiceIntroduction activeIndex={activeIndex} />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import "../styles/pages/cafe24issue/cafe24issue.css";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";

export default function Cafe24Issue() {
    return (
        <div className="issue__container">
            <Navbar />
            <div className="issue__body">
                <p className="issue__title">어머나 앱 연동중 오류가 생겼어요...</p>
                <img src={require('../Assets/popcat.gif')} alt="loading..." />
                <p className="issue__subtitle">하지만 앱 설치를 다시 하시면 괜찮아질 거예요 (아마도)</p>
                <p className="issue_recruit">잠깜만!!! 이걸 보고 웃었다? 입꼬리가 올라갔다?? recruit@incento.kr로 연락주세요! 아마 우리 팀과 잘 맞을거예요ㅎㅎ</p>
            </div>
            <Footer />
        </div>
    );
}

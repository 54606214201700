import React, { useEffect } from "react";
import "../styles/pages/terms/terms.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import termsScript from "../scripts/terms/terms.json";
import privacyScript from "../scripts/terms/privacy.json";
import sellerScript from "../scripts/terms/seller.json";
import { useNavigate } from "react-router-dom";

export default function Privacy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <div className="w-screen mx-auto pb-5">
        <NavBar />
      </div>
      <div className=" bg-[#f3fafd]">
        <div className="flex">
          <div className="terms__container">
            <p className="terms__title">{privacyScript.title}</p>
            <p className="terms__subtitle">{privacyScript.description}</p>
            <div className="terms__section">
              <p>{privacyScript.titles[0]}</p>
              <div className="terms__subsection">
                {privacyScript.collect.map((text, i) => (
                  <ul className="terms__subsection_row">
                    <li>{text}</li>
                  </ul>
                ))}
              </div>
              <p>{privacyScript.titles[1]}</p>
              <p>{privacyScript.details[0]}</p>
              <div className="terms__subsection">
                {privacyScript.usage.map((text, i) => (
                  <ul className="terms__subsection_row">
                    <li>{text}</li>
                  </ul>
                ))}
              </div>
              <p>{privacyScript.titles[2]}</p>
              <p>{privacyScript.details[1]}</p>
              <div className="terms__subsection">
                {privacyScript.third_party.map((text, i) => (
                  <div className="terms__subsection_row">
                    <p>{i + 1 + "."}</p>
                    <p>{text}</p>
                  </div>
                ))}
              </div>

              <p>{privacyScript.titles[3]}</p>
              <p>{privacyScript.details[2]}</p>

              <p>{privacyScript.titles[4]}</p>
              <p>{privacyScript.details[3]}</p>
              <table>
                <tr>
                  <th>수탁업체</th>
                  <th>위탁업무 내용</th>
                  <th>개인정보 보유 및 이용기간</th>
                </tr>
                <tr>
                  <td>AWS (Amazon Web Services)</td>
                  <td>서비스 제공을 위한 서버 운영</td>
                  <td>
                    회원 탈퇴 시, 서비스 종료 시, 혹은 위탁 계약 종료시까지
                  </td>
                </tr>
                <tr>
                  <td>KG 모빌리언스</td>
                  <td>결제처리</td>
                  <td>
                    회원 탈퇴 시, 서비스 종료 시, 혹은 위탁 계약 종료시까지
                  </td>
                </tr>
              </table>
              <p>{privacyScript.details[4]}</p>

              <p>{privacyScript.titles[5]}</p>
              <div className="terms__subsection">
                {privacyScript.security.map((text, i) => (
                  <ul className="terms__subsection_row">
                    <li>{text}</li>
                  </ul>
                ))}
              </div>

              <p>{privacyScript.titles[6]}</p>
              <p>{privacyScript.extra}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen mx-auto py-4 flex justify-center bg-white ">
        <Footer />
      </div>
    </>
  );
}

import React from "react";
import Navbar from "../components/home/Navbar";
import Main from "../components/home/main/Main";
import script from "../scripts/registersuccess.json";
import Footer from "../components/home/Footer";

export default function RegisterSuccess() {
	return (
		<div className="register__success__container">
			<Navbar />
			<Main isHome={false} title={script.title} note={""} subtitle={""} button={script.button} link={"/"} />
			<Footer />
		</div>
	);
}
